import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userInfoPropType, beneficiarySearchPropType } from '../../utils'
import { fetchBeneficiarySearch } from '../actions'
import SimplePagination from '../../components/SimplePagination'
import BeneficiarySearchItem from '../containers/BeneficiarySearchItem'


function scrollTo (selector, duration) {
  const targetSelector = selector || '#search-scroll-back-anchor';
  const scrollDuration = duration || 500;
  jQuery('html, body').animate({ scrollTop: jQuery(targetSelector).offset().top - 70 }, scrollDuration);
}


class BeneficiarySearchResults extends Component {
  constructor(props) {
    super(props)
    this.loadSearchResults = this.loadSearchResults.bind(this)
    this.loadResultsPage = this.loadResultsPage.bind(this)
  }

  render () {
    // `dispatch` is injected by connect() call:
    const { dispatch, userInfo, beneficiarySearch, clickHandler } = this.props
    const { keywords, results, qstring, isFetching, pristine } = beneficiarySearch

    let resultsContent = null,
        resultsList = null,
        latestList = null,
        listTitle = 'Wyniki wyszukiwania';
 
    if (results.items) {
      resultsList = results.items.map((beneficiary, idx) => (
        <BeneficiarySearchItem beneficiary={beneficiary}
                               qstring={qstring}
                               key={idx}
                               itemType="inline"
                               clickHandler={clickHandler}/>
      ))
    }

    if (isFetching) {
      resultsContent = (
        <div className="text-center org-search-in-progress-indicator">
          <span className="fa fa-2x fa-refresh fa-spin" />
        </div>
      )
    } else if (pristine && latestList) {
      //resultsContent = latestList
      //listTitle = 'Wyszukaj lub wybierz z listy ostatnio dodanych:'
    } else if (!results.total && !pristine) {
      resultsContent = (
        <div className="col-xs-12 text-center big-top-margin">
          <label>Szukaliśmy, szukaliśmy ale nic nie znaleźliśmy.</label>
          <br />
          <label>Spróbuj zmienić kryteria wyszukiwania.</label>
          <br />
          <br />
          { results.suggestion ? (
            <label>
              Czy chodziło Ci o
              <a href="#" onClick={function(){this.loadSearchResults(results.suggestion)}}>
                {results.suggestion}
              </a>?
            </label>
          ) : (
              null
          )}
        </div>
        )
    } else {
        resultsContent = resultsList
    }

    return (
      <div className="org-inline-search-results show" id="search-scroll-back-anchor">
        <div className="row results inline-results">
          {resultsContent}
          {!isFetching && results.total ? (
            <div className="col-xs-12 text-center">
              <SimplePagination pagesCount={results.maxPages || 0}
                                currentPage={results.page || 1}
                                loadPageCallback={this.loadResultsPage} />
            </div>
          ) : (null)}
        </div>
      </div>
    )
  }

  loadSearchResults(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes) {
    this.props.dispatch(fetchBeneficiarySearch(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes, this.props.updateQueryString))
  }

  loadResultsPage(pageNum) {
    const { beneficiarySearch, updateQueryString } = this.props
    this.loadSearchResults(beneficiarySearch.keywords, pageNum, beneficiarySearch.fields_of_action, beneficiarySearch.organization_types, beneficiarySearch.geographical_scopes, updateQueryString)
    scrollTo();
  }
}

BeneficiarySearchResults.propTypes = {
  userInfo: userInfoPropType,
  beneficiarySearch: beneficiarySearchPropType,
  updateQueryString: PropTypes.bool
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    beneficiarySearch: state.beneficiarySearch
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(BeneficiarySearchResults)
