import queryString from 'query-string'
import { fetchDiscounts } from './actions'
import { normalizeParsedQS } from '../utils'

export function loadInitialDiscounts (store) {
  const parsedQS = queryString.parse(location.search)
  normalizeParsedQS(parsedQS)

  store.dispatch(fetchDiscounts(parsedQS.type, parsedQS.category, parsedQS.page))
}

export function getDiscountQueryStringChangeHandler(store) {
  return function () {
    const discountSearch = store.getState().discountSearch
    const parsedQS = queryString.parse(location.search)
    normalizeParsedQS(parsedQS)

    if (discountSearch.categories !== parsedQS.category ||
        discountSearch.types !== parsedQS.type ||
        discountSearch.page !== parsedQS.page) {
      store.dispatch(fetchDiscounts(parsedQS.type, parsedQS.category, parsedQS.page))
    }
  }
}
