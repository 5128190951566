import fetch from 'isomorphic-fetch'
import Cookies from 'js-cookie'

export const SET_BENEFICIARY = 'SET_BENEFICIARY'
export const SET_BENEFICIARY_CAUSE = 'SET_BENEFICIARY_CAUSE'

export const FETCH_USER_INFO = 'FETCH_USER_INFO'
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO'
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO'

export const REQUEST_FMEXT_INFO = 'REQUEST_FMEXT_INFO'
export const RECEIVE_FMEXT_INFO = 'RECEIVE_FMEXT_INFO'


export const FETCH_1ZL_INFO = 'FETCH_1ZL_INFO'
export const REQUEST_1ZL_INFO = 'REQUEST_1ZL_INFO'
export const RECEIVE_1ZL_INFO = 'RECEIVE_1ZL_INFO'

export function requestSetBeneficiary(beneficiary) {
  return { type: SET_BENEFICIARY, beneficiary: beneficiary };
}


export function requestUserInfo() {
  return { type: REQUEST_USER_INFO };
}

export function receiveUserInfo(json) {
  return {
    type: RECEIVE_USER_INFO,
    userInfo: {
      user: json.user,
      beneficiary: json.beneficiary,
      beneficiaryCause: json.beneficiary_cause
    },
    json: json,
    receivedAt: Date.now()
  };
}

export function fetchUserInfo() {
  return function (dispatch) {
    // show that we requested user info
    dispatch(requestUserInfo())

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')

    return fetch(
      '/api/v2/user-info/',
      {
        credentials: 'same-origin',
        headers: headers
      })
      .then(response => response.json())
      .then(json =>
        dispatch(receiveUserInfo(json))
      )
      // TODO: do something on error
  }
}

export function setBeneficiary(beneficiary) {
  return function (dispatch) {
    // show that we requested changed beneficiary
    dispatch(requestSetBeneficiary(beneficiary))
    dispatch(requestUserInfo())

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    headers.append('X-CSRFToken', Cookies.get('csrftoken'))

    return fetch(
      '/api/v2/user-info/',
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({'beneficiary': beneficiary.id})
      })
      .then(function(response) {
        if (response.status === 200) {
          response.json().then(function (data) {
            dispatch(receiveUserInfo(data))
            if(data.beneficiary.id==10){
              humane.log('Organizacja zostanie wybrana później')
            } else {
              humane.log('Teraz wspierasz: ' + data.beneficiary.name)  
            }
            
          })
        } else {
          console.log('ERROR setting beneficiary:')
          console.log(response.body)
        }
      })
      // TODO: do something on error
  }
}

export function requestFMExtInfo() {
  return { type: REQUEST_FMEXT_INFO };
}

export function receiveFMExtInfo(fmextInfo) {
  return {
    type: RECEIVE_FMEXT_INFO,
    fmextInfo: fmextInfo,
    receivedAt: Date.now()
  };
}

export function fetchFMExtInfo() {
  return function (dispatch) {
    // show that we requested user info
    dispatch(requestFMExtInfo())
    window.addEventListener("message", function(event) {
      if (event.source === window && event.data.action && event.data.action === 'FMExt.PresenceOk') {
        dispatch(receiveFMExtInfo({isInstalled:true}))
      }
    });
    // need to delay posting a message as to let eventListener to actually listen
    setTimeout(function () {
      window.postMessage({action: "FMExt.CheckPresence"}, "*")
    }, 10)
  }
}


export function request1zlInfo() {
  return { type: REQUEST_1ZL_INFO };
}

export function receive1zlInfo(json) {
  return {
    type: RECEIVE_1ZL_INFO,
    fmext1zlInfo: {
      isGranted: json.granted,
      beneficiary: json.beneficiary,
      isEmailConfirmed: json.email_confirmed
    },
    json: json,
    receivedAt: Date.now()
  };
}

export function fetch1zlInfo(grant1zl, silent) {
  return function (dispatch) {
    // show (or not) that we requested 1zl info
    if (!silent) {
      dispatch(request1zlInfo())
    }

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    headers.append('X-CSRFToken', Cookies.get('csrftoken'))

    let requestConfig = {
      credentials: 'same-origin',
      headers: headers
    }

    if (grant1zl) {
      requestConfig.method = 'POST'
      requestConfig.body = JSON.stringify({grant1zl: true})
    }

    return fetch('/api/v2/1zl-info/', requestConfig)
      .then(response => response.json())
      .then(json =>
        dispatch(receive1zlInfo(json))
      )
  }
}
