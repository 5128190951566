import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { beneficiarySearchPropType } from '../../utils'

function get_label_for_option (optionId, optionChoices) {
  return optionChoices.reduce((prev, possibleOption) => {
    if (parseInt(optionId) === possibleOption.id) {
      console.log('option label:', optionId, possibleOption.id);
      return possibleOption.name;
    }
    return prev;
  }, '');
}

export class SearchCriteriaLabel extends Component {
  render () {
    const { name, value, label, action } = this.props

    return (
      <div className="criteria">
        <span className="name">{label}</span>
        <div className="close"
             onClick={() => {action(name, value)}}
             data-type={name}
             data-value={value}
        ></div>
      </div>
    )
  }
}

SearchCriteriaLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  //value: PropTypes.number,
  action: PropTypes.func
}


export default class BeneficiarySearchCriteriaLabels extends Component {
  constructor(props) {
    super(props)
    this.getCriteriaLabels = this.getCriteriaLabels.bind(this)
    this.removeCriteria = this.removeCriteria.bind(this)
  }

  render () {
    const { beneficiarySearch } = this.props

    if (!beneficiarySearch.pristine) {
      const criteriaLabels = this.getCriteriaLabels()
      if (criteriaLabels.length) {
        return <div className="criteria-labels">{criteriaLabels}</div>
      }
    }
    return null
  }

  getCriteriaLabels() {
    const { keywords, page,
            fields_of_action, fields_of_action_choices,
            organization_types, organization_types_choices,
            geographical_scopes, geographical_scopes_choices  } = this.props.beneficiarySearch
    let criteriaLabels = []

    if (keywords) {
        criteriaLabels.push(
            <SearchCriteriaLabel name="keywords" label={keywords} action={this.removeCriteria} key="kw" />)
    }
    if (fields_of_action && fields_of_action.length) {
      fields_of_action.map((val, idx) => {
        const label = get_label_for_option(val, fields_of_action_choices)
        criteriaLabels.push(
            <SearchCriteriaLabel name="fields_of_action" label={label} value={val} action={this.removeCriteria} key={'fa'+idx} />)
      })
    }
    if (organization_types && organization_types.length) {
      organization_types.map((val, idx) => {
        const label = get_label_for_option(val, organization_types_choices)
        criteriaLabels.push(
            <SearchCriteriaLabel name="organization_types" label={label} value={val} action={this.removeCriteria} key={'ot'+idx} />)
      })
    }
    if (geographical_scopes && geographical_scopes.length) {
      geographical_scopes.map((val, idx) => {
        const label = get_label_for_option(val, geographical_scopes_choices)
        criteriaLabels.push(
            <SearchCriteriaLabel name="geographical_scopes" label={label} value={val} action={this.removeCriteria} key={'gs'+idx} />)
      })
    }
    return criteriaLabels
  }

  removeCriteria(criteriaType, criteriaValue) {
    const { keywords, page, fields_of_action, organization_types, geographical_scopes  } = this.props.beneficiarySearch
    const { loadSearchResults  } = this.props

    let index = -1;
    switch (criteriaType) {
      case 'keywords':
        loadSearchResults(undefined, page, fields_of_action, organization_types, geographical_scopes)
        break
      case 'fields_of_action':
        let newFieldsOfAction = (fields_of_action && fields_of_action.length ? fields_of_action.slice() : null)
        index = newFieldsOfAction.indexOf(criteriaValue)
        if (index > -1) newFieldsOfAction.splice(index, 1)
        loadSearchResults(keywords, page, newFieldsOfAction, organization_types, geographical_scopes)
        break
      case 'organization_types':
        let newOrganizationTypes = (organization_types && organization_types.length ? organization_types.slice() : null)
        index = newOrganizationTypes.indexOf(criteriaValue)
        if (index > -1) newOrganizationTypes.splice(index, 1)
        loadSearchResults(keywords, page, fields_of_action, newOrganizationTypes, geographical_scopes)
        break
      case 'geographical_scopes':
        let newGeographicalScopes = (geographical_scopes && geographical_scopes.length ? geographical_scopes.slice() : null)
        index = newGeographicalScopes.indexOf(criteriaValue)
        if (index > -1) newGeographicalScopes.splice(index, 1)
        loadSearchResults(keywords, page, fields_of_action, organization_types, newGeographicalScopes)
        break
    }
  }
}

BeneficiarySearchCriteriaLabels.propTypes = {
  beneficiarySearch: beneficiarySearchPropType,
  loadSearchResults: PropTypes.func.isRequired
}
