import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { setBeneficiary } from '../actions'
import BeneficiarySelectModal from '../beneficiary-search/components/BeneficiarySelectModal'


class DiscountsBeneficiary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      isRedirecting: false,
      isLoaded: false,
    }
    this.DiscountsBeneficiarySelect = this.DiscountsBeneficiarySelect.bind(this)
    this.modalClosedHandler = this.modalClosedHandler.bind(this)
  }

  modalClosedHandlerBack(loginModal){
    this.setState({showModal: false});
    jQuery(loginModal).modal('show');
  }

  render () {
    const { dispatch, userInfo, goToUrl, hideDismiss, label, customClasses, partner, loginModal } = this.props



    return (
      <span class="btn-go-to-shop-wrapper { this.state.isLoaded ? 'link-visible' : '' }">
      {
        (userInfo.beneficiary === null || userInfo.beneficiary.id==10) ? (
          <span onClick={this.DiscountsBeneficiarySelect}
            data-go-url={goToUrl}
            data-analytics="beneficiary_choose_modal"
            id="discounts-beneficiary-box-select"
            className={(customClasses ? customClasses : "discounts-beneficiary-box-select")}>
            {(label? label : 'wybierz organizację')}
          </span>
          
        ) : (
          <span></span>  
        )

      }
      <BeneficiarySelectModal show={this.state.showModal}
                                  closedHandler={this.modalClosedHandler}
                                  closedHandlerBack={this.modalClosedHandlerBack.bind(this)}
                                  dispatch={dispatch} 
                                  userInfo={userInfo}
                                  hideDismiss={hideDismiss}
                                  loginModal={(loginModal ? loginModal : false)}/>
      </span>
    )
  }

  DiscountsBeneficiarySelect (e) {
    const { userInfo } = this.props;
    jQuery('.modal').modal('hide');
    this.setState({showModal: true});
  }

  

  modalClosedHandler (selectedBeneficiary) {
    const { dispatch, goToUrl, loginModal } = this.props
    if (selectedBeneficiary) { 
      if(this.props.userInfo.beneficiary !== null && selectedBeneficiary.id==10){
        // do nothing
      } else {
        const joinChar = (goToUrl.indexOf('?') > -1) ? '&' : '?';
        if(this.props.partner){
          window.location = goToUrl + joinChar + 'beneficiary_id=' + selectedBeneficiary.id + '&iframe=fromModal&partner='+ this.props.partner;
        } else {
          window.location = goToUrl + joinChar + 'beneficiary_id=' + selectedBeneficiary.id;
        }
      }

    } else {
      this.setState({isRedirecting: false}) // just disable spinner
    }

    this.setState({showModal: false})  // hide modal
  }
}

DiscountsBeneficiary.propTypes = {
  goToUrl: PropTypes.string,
  userInfo: PropTypes.object,  // userInfoPropType ?
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    isLoaded: true
  }
}

// Wrap the component to inject dispatch and state into it
DiscountsBeneficiary = connect(mapStateToProps)(DiscountsBeneficiary)

export { DiscountsBeneficiary as default }
