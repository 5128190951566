import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SimplePagination from '../../components/SimplePagination'
import { partnerSearchPropShape } from '../../utils'


class PartnerItem extends Component {
  render () {
    const { partner, qstring } = this.props
    const classes = classNames("col-xs-6 col-sm-4 col-md-3 col-lg-2 partner-box")
    const url = partner.detail_url + (qstring ? ('?' + qstring) : '')

    return (
        <div className={classes}>

            <a href={url}>
                <div className="partner-logo">
                    <img src={partner.thumbnail_url} className="v-center" alt={partner.name} />
                </div>
                <p className="style-2" style={{color:"black"}}>{partner.name}</p>
                <p className="donation-value">{partner.donation_value_text}</p>
            </a>
        </div>
    )
  }
}

PartnerItem.propTypes = {
  partner: PropTypes.object.isRequired,
  qstring: PropTypes.string
}


class PartnerSearchResults extends Component {
  render () {
    const { categoryId, keywords, results, qstring, isFetching, loadResultsPage } = this.props
    let resultsContent = null;

    if (isFetching) {
      resultsContent = (
          <div className="text-center big-top-margin">
              <span className="fa fa-2x fa-refresh fa-spin" />
          </div>
      )
    }
    else if (!results.total) {
      if (categoryId) {
        resultsContent = (
            <div className="col-xs-12 text-center big-top-margin">
                <label>
                    Wygląda na to, że ta kategoria jest pusta...
                </label>
            </div>
        )
      } else {
        resultsContent = (
            <div className="col-xs-12 text-center big-top-margin">
                <h2 className="title-new">
                    <i className="fa fa-frown-o" /><br />
                    Szukaliśmy "{keywords}" ale nie znaleźliśmy.<br />
                    Wpisz inną nazwę do wyszukiwania.
                </h2>
                <br />
                <br />
                { results.suggestion ? (
                    <label>
                        Czy chodziło Ci o
                        <a href={"/sklepy/?keywords="+results.suggestion}>
                            {results.suggestion }
                        </a>?
                    </label>
                ) : (null)}
                <br />
                <label>
                   <a href="https://fanimani.pl/pomoc/zaproponuj-sklep/">Wyślij propozycję sklepu / partnera </a>
                </label>
            </div>
        )
      }
    } else {
      resultsContent = results.items.map((partner, idx) => (
          <PartnerItem partner={partner} qstring={qstring} key={idx} />
      ))
      /*
      resultsContent += (
          <div className="col-xs-12">
              <div className="pagination-links">
                  (pagination)
              </div>
          </div>
      )
      */
    }

    return (
        <div className="shops-list" id="search-scroll-back-anchor">
            <div className="row">
                {resultsContent}
                {!isFetching ? (
                  <div className="col-xs-12">
                    <SimplePagination pagesCount={results.maxPages || 0}
                                      currentPage={results.page || 1}
                                      loadPageCallback={loadResultsPage} />
                  </div>
                ) : (null)}
            </div>
        </div>
    )
  }
}

PartnerSearchResults.propTypes = partnerSearchPropShape

export { PartnerItem, PartnerSearchResults }
export default PartnerSearchResults