import { DEFAULT_STATE } from '../utils'

import {
  REQUEST_PARTNER_SEARCH,
  REQUEST_PARTNER_CATEGORY,
  FETCH_PARTNER_CATEGORY,
  FETCH_PARTNER_SEARCH,
  RECEIVE_PARTNER_SEARCH,
  RECEIVE_PARTNER_CATEGORY
} from './actions'

export function partnerSearchHandler(state=DEFAULT_STATE.partnerSearch, action={}) {
  // state is partnerSearch here
  switch (action.type) {
    case RECEIVE_PARTNER_SEARCH:
    case RECEIVE_PARTNER_CATEGORY:
      return Object.assign({}, state, {
        isFetching: false,
        results: action.results
      })
    case REQUEST_PARTNER_SEARCH:
      return Object.assign({}, state, {isFetching: true,
                                       pristine: false,
                                       categoryId: null,
                                       keywords: action.keywords,
                                       page: action.page})
    case REQUEST_PARTNER_CATEGORY:
      return Object.assign({}, state, {isFetching: true,
                                       categoryId: action.categoryId,
                                       keywords: '',
                                       page: action.page})
    default:
      return state;
  }
}
