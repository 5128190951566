import { DEFAULT_STATE } from '../utils'

import {
  RECEIVE_DISCOUNT_SEARCH,
  REQUEST_DISCOUNT_SEARCH,
  FETCH_DISCOUNT_SEARCH
} from './actions'

export function discountSearchHandler(state=DEFAULT_STATE.discountSearch, action={}) {
  // state is discountSearch here
  switch (action.type) {
    case RECEIVE_DISCOUNT_SEARCH:
      return Object.assign({}, state, {
        isFetching: false,
        results: action.results
      })
    case REQUEST_DISCOUNT_SEARCH:
      return Object.assign({}, state, {
        isFetching: true,
        pristine: false,
        categories: action.categories,
        types: action.types,
        page: action.page
      })
    default:
      return state;
  }
}
