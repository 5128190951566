import queryString from 'query-string'
import { fetchBeneficiarySearch } from './actions'
import { normalizeParsedQS } from '../utils'

export function loadBeneficiaryInitialSearchResults (store, overrideParams) {
  let parsedQS = queryString.parse(location.search)
  normalizeParsedQS(parsedQS)

  if (parsedQS.keywords || parsedQS.fields_of_action || parsedQS.organization_types || parsedQS.geographical_scopes) {
    if (overrideParams) {
      parsedQS = Object.assign({}, parsedQS, overrideParams)
    }
    store.dispatch(fetchBeneficiarySearch(
        parsedQS.keywords, parsedQS.page, parsedQS.fields_of_action, parsedQS.organization_types,
        parsedQS.geographical_scopes, false, parsedQS.parent, parsedQS.searchType))
  }
}

export function getBeneficiaryQueryStringChangeHandler(store) {
  return function () {
    const beneficiarySearch = store.getState().beneficiarySearch
    let parsedQS = queryString.parse(location.search)
    normalizeParsedQS(parsedQS)

    if (parsedQS.keywords || parsedQS.fields_of_action || parsedQS.organization_types || parsedQS.geographical_scopes) {
      if (beneficiarySearch.keywords !== parsedQS.keywords ||
          beneficiarySearch.page !== parsedQS.page ||
          beneficiarySearch.fields_of_action !== parsedQS.fields_of_action ||
          beneficiarySearch.organization_types !== parsedQS.organization_types ||
          beneficiarySearch.geographical_scopes !== parsedQS.geographical_scopes) {
        store.dispatch(fetchBeneficiarySearch(parsedQS.keywords, parsedQS.page, parsedQS.fields_of_action, parsedQS.organization_types, parsedQS.geographical_scopes))
      }
    }
  }
}

export const defaultSliderSettings = {
  dots: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 480,
      settings: {slidesToShow: 1, slidesToScroll: 1}
    },
    {
      breakpoint: 640,
      settings: {slidesToShow: 2, slidesToScroll: 2}
    },
    {
      breakpoint: 768,
      settings: {slidesToShow: 3, slidesToScroll: 3}
    },
    {
      breakpoint: 992,
      settings: {slidesToShow: 4, slidesToScroll: 4}
    },
    {
      breakpoint: 1280,
      settings: {slidesToShow: 5, slidesToScroll: 5}
    }
  ]
}

export const defaultSliderSettingsNarrow = {
  dots: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 640,
      settings: {slidesToShow: 2, slidesToScroll: 2}
    },
    {
      breakpoint: 768,
      settings: {slidesToShow: 3, slidesToScroll: 3}
    },
    {
      breakpoint: 1200,
      settings: {slidesToShow: 4, slidesToScroll: 4}
    },
    {
      breakpoint: 2000,
      settings: {slidesToShow: 5, slidesToScroll: 5}
    }
  ]
}

export function localeGet(localeItem,localeSelected,localeDefault){
  let locales = {
    'header': {
      'pl': 'Wybierz organizację',
      'en': 'Select an organization',
      'uk': 'Виберіть організацію'
    },
    'login': {
      'pl': 'Zaloguj się',
      'en': 'Login',
      'uk': 'авторизуватися'
    },
    'choose_later': {
      'pl': 'Wybiorę organizację później',
      'en': 'Choose later',
      'uk': 'Пізніше виберу організацію'
    },
    'choose': {
      'pl': 'Wybieram',
      'en': 'Choose',
      'uk': 'Виберіть'
    }
  }

  let locale=locales[localeItem][localeDefault];

  if(typeof(locales[localeItem][localeSelected])!='undefined'){
    locale=locales[localeItem][localeSelected];
  }

  return locale;
};