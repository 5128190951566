import fetch from 'isomorphic-fetch'
import queryString from 'query-string'

export const REQUEST_DISCOUNT_SEARCH = 'REQUEST_DISCOUNT_SEARCH'  // for visual feedback'
export const FETCH_DISCOUNT_SEARCH = 'FETCH_DISCOUNT_SEARCH'
export const RECEIVE_DISCOUNT_SEARCH = 'RECEIVE_DISCOUNT_SEARCH'


export function requestDiscountSearch(types, categories, page, updateQueryString) {
  if (updateQueryString) {
    let parsedQS = queryString.parse(location.search)
    parsedQS.type = types
    parsedQS.category = categories
    parsedQS.page = page
    if (!types) delete parsedQS.type
    if (!categories) delete parsedQS.category
    if (!page) delete parsedQS.page
    const new_qs = queryString.stringify(parsedQS)
    const new_url = window.location.pathname + (new_qs ? ("?" + new_qs) : "")
    history.pushState(null, "", new_url);
  }
  return {
    type: REQUEST_DISCOUNT_SEARCH,
    types: types,
    categories: categories,
    page: page
  };
}


export function receiveDiscountSearch(json) {
  return {
    type: RECEIVE_DISCOUNT_SEARCH,
    results: {
      items: json['results'],
      total: json['total'],
      maxPages: json['max_pages'],
      page: json['page']
    },
    json: json,
    receivedAt: Date.now()
  };
}


export function fetchDiscounts(types, categories, page, updateQueryString) {
  return function (dispatch) {
    // show that we requested user info
    dispatch(requestDiscountSearch(types, categories, page, updateQueryString))

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')

    let params = {type: types, category: categories, page: page, page_size: 30, visible: true}
    if (!types) delete params.type
    if (!categories) delete params.category
    if (!page) delete params.page

    const parsedQS = queryString.parse(location.search)
    if (parsedQS.hasOwnProperty('iframe') && parsedQS['beneficiary_id']) {
      params.beneficiary_id = parsedQS.beneficiary_id
    }
    
    params = queryString.stringify(params)

    return fetch(
      '/api/v2/discount-codes/' + (params ? ('?' + params) : ''),
      {
        credentials: 'same-origin',
        headers: headers
      })
      .then(response => response.json())
      .then(json =>
        dispatch(receiveDiscountSearch(json))
      )
      // TODO: do something on error
  }
}
