import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { userInfoPropType, discountSearchPropType } from '../../utils'
import DiscountSearchResults from '../components/DiscountSearchResults'
import DiscountTypeSelector from '../components/DiscountTypeSelector'
import DiscountCategorySelector from '../components/DiscountCategorySelector'
import { fetchDiscounts } from '../actions'


class DiscountSearchFull extends Component {
  constructor(props) {
    super(props)
    this.loadDiscounts = this.loadDiscounts.bind(this)
    this.loadCategories = this.loadCategories.bind(this)
    this.loadTypes = this.loadTypes.bind(this)
    this.loadPage = this.loadPage.bind(this)
  }

  render () {
    // `dispatch` is injected by connect() call:
    const { dispatch, userInfo, discountSearch } = this.props

    return (
        <div className="row discount-search">
            <div className="col-sm-3 col-md-2 filters">
                <DiscountTypeSelector discountSearch={discountSearch} loadTypes={this.loadTypes} />
                <DiscountCategorySelector discountSearch={discountSearch} loadCategories={this.loadCategories} />
            </div>

            <div className="col-sm-9 col-md-10 discounts" id="partners_list_container">
                <DiscountSearchResults discountSearch={discountSearch} userInfo={userInfo} loadPage={this.loadPage} />
            </div>
        </div>
    )
  }

  loadDiscounts(types, categories, page) {
    this.props.dispatch(fetchDiscounts(types, categories, page, this.props.updateQueryString))
  }

  loadCategories(categories) {
    this.loadDiscounts(this.props.discountSearch.types, categories, null)
  }

  loadTypes(types) {
    this.loadDiscounts(types, this.props.discountSearch.categories, null)
  }

  loadPage(pageNum) {
    this.loadDiscounts(this.props.discountSearch.types, this.props.discountSearch.categories, pageNum)
  }
}

DiscountSearchFull.propTypes = {
  userInfo: userInfoPropType,
  discountSearch: discountSearchPropType,
  updateQueryString: PropTypes.bool
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    discountSearch: state.discountSearch
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(DiscountSearchFull)