import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userInfoPropType, beneficiarySearchPropType } from '../utils'
import BeneficiarySearchBar from '../beneficiary-search/containers/BeneficiarySearchBar'
import BeneficiarySearchResults from '../beneficiary-search/containers/BeneficiarySearchResults'

class SchroniskaSearch extends Component {
  render () {
    const { dispatch, userInfo, parentId } = this.props
    
    return (
        <div className="schroniska-search">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <BeneficiarySearchBar updateQueryString={true} parentId={parentId}/>
              </div>
            </div>
          </div>
          <BeneficiarySearchResults updateQueryString={true} parentId={parentId}
                                    itemType={"autoselect-link"} />
        </div>
    )
  }
}


SchroniskaSearch.propTypes = {
  userInfo: userInfoPropType,
  parentId: PropTypes.number
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  }
}

// Wrap the component to inject dispatch and state into it
SchroniskaSearch = connect(mapStateToProps)(SchroniskaSearch)

export { SchroniskaSearch as default }
