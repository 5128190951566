import React, { Component, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

//TODO: use react-glidejs instead of the globally available
//not working now, because react-glidejs initiates automatically when the modal is still not visible
//import Glide, { Slide } from 'react-glidejs';
import { Modal } from 'react-bootstrap'
import { userInfoPropType, beneficiarySearchPropType, defaultSliderSettings, localeGet } from '../utils'
import BeneficiarySearchItem from '../containers/BeneficiarySearchItem'
import BeneficiarySearchBar from '../containers/BeneficiarySearchBar'
import BeneficiaryInlineSearchResults  from '../containers/BeneficiaryInlineSearchResults'

import 'react-glidejs/dist/index.css';

class BeneficiarySelectModal extends Component {
  render () {
    const { dispatch, show, closedHandler, userInfo, hideDismiss, loginModal, lang, parentId, hideSearch, defaultBeneficiaryId, hideLogin, langDefault} = this.props
    
    //const gliderRef = useRef(null);

    let localeDefault = langDefault ? langDefault : 'pl';
    let sugestedBeneficiaries = []
    let sliderSettings = Object.assign({}, defaultSliderSettings)
    let noBeneficiary = {id: (defaultBeneficiaryId ? defaultBeneficiaryId : 10), name: localeGet('choose_later',lang,localeDefault) }
    let handleClose = (e) => { 
      e.preventDefault();
      this.props.closedHandlerBack(loginModal)
    };
    let glidejsLoaded = false;

    let glidejsLoad = () => {
      if(!glidejsLoaded){
        // TODO: move breakpoints to a common setting
        new Glide('#glide-beneficiary-suggestions',{
            keyboard: false,
            perView: 5,
            breakpoints: {
              1024: {
                perView: 4
              },
              992: {
                perView: 3
              },
              640: {
                perView: 2
              },
          }

        }).mount();
        glidejsLoaded=true;
      }
    };

    if (FM && FM.popularBeneficiaries && FM.popularBeneficiaries.length) {
      // Make list of suggested beneficiaries (if any)
      sugestedBeneficiaries = FM.popularBeneficiaries.map((beneficiary, idx) => (
          
          <li class="glide__slide" key={idx}>
            <div>
              <BeneficiarySearchItem beneficiary={beneficiary} 
                                     userInfo={{}}
                                     className="result result-inline organization"
                                     itemType={"inline"}
                                     clickHandler={closedHandler}/>
            </div>
          </li>
          
      ))
    }

    if (FM && FM.e4uBeneficiaries && FM.e4uBeneficiaries.length) {
      sugestedBeneficiaries = FM.e4uBeneficiaries.map((beneficiary, idx) => (
          <li class="glide__slide" key={idx}>
            <div>
              <BeneficiarySearchItem beneficiary={beneficiary} 
                                   userInfo={{}}
                                   className="result result-inline organization"
                                   itemType={"inline"}
                                   clickHandler={closedHandler}
                                   lang={lang}
                                   localeDefault={localeDefault}/>
            
            </div>
          </li>
      ))
    }

    return (
      <span>
        <Modal show={show} onHide={closedHandler} onShow={glidejsLoad} size="lg" keyboard={true} backdrop={true}
               className={"beneficiary-search-inline"}>
          <Modal.Body>
            <h2>
              { localeGet('header',lang,localeDefault) }
              { userInfo.isAnonymous ? (
                hideLogin ? (
                  (null)
                ) : (
                  loginModal ? (
                    <small><br/>Masz konto? <a href="#" class="modalDismiss" onClick={handleClose}>{ localeGet('login',lang,localeDefault) }</a></small>
                  ) : (
                    <small><br/>Masz konto? <a href={'/konto/zaloguj/?next='+window.location.pathname}>{ localeGet('login',lang,localeDefault) }</a></small>
                  )
                )  
              ) : (
                (null)
              ) }
            </h2>
            <div id="beneficiary-suggestions" className="beneficiary-suggestions">
              <div className="glide glide-beneficiary-suggestions" id="glide-beneficiary-suggestions">
                <div class="glide__track glide__track--modal" data-glide-el="track">
                  <ul class="glide__slides">
                    {sugestedBeneficiaries}
                  </ul>
                </div>
                <div class="glide__arrows glide__arrows--modal" data-glide-el="controls">
                  <span class="glide__arrow glide__arrow--left" data-glide-dir="<"><i class="fa fa-chevron-left"/></span>
                  <span class="glide__arrow glide__arrow--right" data-glide-dir=">"><i class="fa fa-chevron-right"/></span>
                </div>
              </div>
            </div>
            {
              hideSearch ? (
                (null)
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <BeneficiarySearchBar updateQueryString={false}/>
                  </div>
                </div>
              )
            }
            
            <BeneficiaryInlineSearchResults updateQueryString={false} clickHandler={closedHandler}/>
            { hideDismiss ? (
              (null)
            ) : (
              <div className="row">
                <div className="col-md-12 text-center">
                  <BeneficiarySearchItem beneficiary={noBeneficiary} 
                                     userInfo={{}}
                                     className="result result-inline organization noBeneficiaryLink"
                                     itemType={"inline"}
                                     data-analytics="beneficiary_choose_later"
                                     clickHandler={closedHandler}
                                     lang={lang}
                                     localeDefault={localeDefault}/>
                </div>
              </div>
            ) }
          </Modal.Body>
        </Modal>
      </span>
    )
  }
}

BeneficiarySelectModal.propTypes = {
//  beneficiarySearch: PropTypes.object,
  beneficiary: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    main_image: PropTypes.string,
  })
}

export { BeneficiarySelectModal as default }
