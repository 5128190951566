import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { setBeneficiary } from '../../actions'
import { localeGet } from '../utils'


export class BeneficiaryItemLink extends Component {
  render () {
    const { beneficiary, qstring, className, autoSelectLink, autoSelectLinkSelf } = this.props
    const defaultClassName = "col-xs-6 col-sm-3 col-md-3 result result-inline organization"
    let url = (beneficiary.url || beneficiary.detail_url) + (qstring ? ('?' + qstring) : '')
    if (autoSelectLink) {
      url = (beneficiary.url || beneficiary.detail_url) + '?beneficiary_id=' + beneficiary.id
    }
    if (autoSelectLinkSelf) {
      url = window.location.origin + '/mobileapp-beneficiary-selected/?beneficiary_id=' + beneficiary.id
    }

    let backgroundLogo = beneficiary.thumbnail || beneficiary.main_image ||  window.FM.STATIC_URL+'img/no-image.png';

    return (
      <div className={className || defaultClassName}>
        <div className="resultInner">
          <div className="wrap">
            <a href={url}>
              <div className="image-wrap-new" style={{  
                backgroundImage: "url(" + (beneficiary.thumbnail || beneficiary.main_image || window.FM.STATIC_URL+'img/no-image.png') + ")",
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}>
            </div>
              <p className="name">{beneficiary.name}</p>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

BeneficiaryItemLink.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  qstring: PropTypes.string,
  className: PropTypes.string,
  autoSelectLink: PropTypes.bool,  // clicking will automatically select
  autoSelectLinkSelf: PropTypes.bool  // clicking will automatically select
}


export class BeneficiaryItemSelectFlat extends Component {
  constructor(props) {
    super(props)
    this.setUserBeneficiary = this.setUserBeneficiary.bind(this)
  }

  render () {
    const { beneficiary, className } = this.props
    const defaultClassName = "col-xs-12 item beneficiary-list-item"

    return (
      <div className={className || defaultClassName}>
        <div className="resultInner">
          <div className="image-wrap">
            <div className="image-wrap-new" style={{  
                backgroundImage: "url(" + (beneficiary.thumbnail || beneficiary.main_image || window.FM.STATIC_URL+'img/no-image.png') + ")",
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}>
            </div>
          </div>
          <div className="description">
            <h3 className="header-small">{beneficiary.name}</h3>
            <div className="address"></div>
            <div className="info">
                { (beneficiary.address || beneficiary.post_town) ? (
                    <span>
                        {beneficiary.address || beneficiary.post_town}
                        <br />
                    </span>
                ) : (null)}
                { beneficiary.type === 'project' ? (<span>{"Projekt w " + beneficiary.parent_name + ". "}</span>) : null }
                { beneficiary.type === 'pupil' ? (<span>{"Podopieczny w " + beneficiary.parent_name + ". "}</span>) : null }
                { beneficiary.fields_of_action_txt ? (
                    <span>Działa na rzecz: {beneficiary.fields_of_action_txt}. </span>
                ) : null }
                { beneficiary.organization_types_txt ? (
                    <span>Rodzaj: {beneficiary.organization_types_txt}. </span>
                ) : null }
            </div>
            <div className="overlay">
              <button onClick={this.setUserBeneficiary} type="button"
                 className="btn btn-green btn-small instalacja-wtyczki">
                  Wspieraj i podaruj 1zł &nbsp;
                  <span className="fa fa-chevron-right" />
              </button>
              <div className="text">
                  {beneficiary.name} <br/>
                  {beneficiary.post_town}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  setUserBeneficiary(e) {
      this.props.dispatch(setBeneficiary(this.props.beneficiary))
  }
}

BeneficiaryItemSelectFlat.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  qstring: PropTypes.string,
  className: PropTypes.string,
  dispatch: PropTypes.func
}


export class BeneficiaryItemSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {forceOverlay: false}

    this.setUserBeneficiary = this.setUserBeneficiary.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
  }

  render () {
    const { beneficiary, userInfo, className, lang, localeDefault } = this.props
    const showLoader = userInfo.isSettingBeneficiary || this.state.forceOverlay;
    const defaultClassName = "col-xs-6 col-sm-3 col-md-2 result result-inline organization "
    const overlayStyles = this.state.forceOverlay ? {'display': 'block'} : null
    const btnClasses = classNames("btn btn-red instalacja-wtyczki", {
      "is-loading": showLoader
    })
    const btnIconClasses = classNames("fa", {
      "fa-chevron-right": !showLoader,
      "fa-spin": showLoader,
      "fa-circle-o-notch": showLoader,
    })

    return (
      <div className={className || defaultClassName}>
        <div className="resultInner">
          <div className="wrap">
            <div className="image-wrap-new" style={{  
                backgroundImage: "url(" + (beneficiary.thumbnail || beneficiary.main_image || '/static/img/no-image.png') + ")",
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}>
            </div>
            <p className="name">{beneficiary.name}</p>
            <div className="overlay" style={overlayStyles} onClick={this.onClickHandler}>
              <button type="button" className={btnClasses}
                      disabled={userInfo.isSettingBeneficiary || this.state.forceOverlay}
                      data-analytics="beneficiary_choose"
                      data-analytics-value={beneficiary.id}
                      >
                {localeGet('choose',lang,localeDefault)} <span className={btnIconClasses} />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onClickHandler () {
    if (this.props.clickHandler === undefined) {
      this.setUserBeneficiary()
    } else {
      this.props.clickHandler(this.props.beneficiary)
    }
  }

  setUserBeneficiary(e) {
    this.setState({forceOverlay: true})
    this.props.dispatch(setBeneficiary(this.props.beneficiary))
  }
}

BeneficiaryItemSelect.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  clickHandler: PropTypes.func,
  userInfo: PropTypes.object,
  qstring: PropTypes.string
}





/*
This is main "container" component.
*/
class BeneficiarySearchItem extends Component {
  render () {
    if (this.props.itemType === 'inline-flat') {
      return <BeneficiaryItemSelectFlat {...this.props}/>
    } else if (this.props.itemType === 'inline') {
      return <BeneficiaryItemSelect {...this.props}/>
    } else if (this.props.itemType === 'autoselect-link-self') {
      return <BeneficiaryItemLink {...this.props} autoSelectLinkSelf={true}/>
    } else if (this.props.itemType === 'autoselect-link') {
      return <BeneficiaryItemLink {...this.props} autoSelectLink={true}/>
    }
    return <BeneficiaryItemLink {...this.props}/>
  }
}

BeneficiarySearchItem.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  qstring: PropTypes.string,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  itemType: PropTypes.string
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,  // actually none, we just need access to dispatch()
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(BeneficiarySearchItem)
