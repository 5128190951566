import fetch from 'isomorphic-fetch'
import queryString from 'query-string'

export const REQUEST_BENEFICIARY_SEARCH = 'REQUEST_BENEFICIARY_SEARCH'  // for visual feedback
export const FETCH_BENEFICIARY_SEARCH = 'FETCH_BENEFICIARY_SEARCH'
export const RECEIVE_BENEFICIARY_SEARCH = 'RECEIVE_BENEFICIARY_SEARCH'

export function requestBeneficiarySearch(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes, updateQueryString) {
  if (updateQueryString) {
    const parsedQS = Object.assign(
      queryString.parse(location.search),
      {
        keywords: keywords || undefined,
        page: page  || undefined,
        fields_of_action: fieldsOfAction || undefined,
        organization_types: organizationTypes || undefined,
        geographical_scopes: geographicalScopes || undefined
      }
    )

    const new_qs = queryString.stringify(parsedQS)
    const new_url = window.location.pathname + (new_qs ? ("?" + new_qs) : "")
    history.pushState(null, "", new_url);
  }
  return {
    type: REQUEST_BENEFICIARY_SEARCH,
    keywords: keywords,
    fieldsOfAction: fieldsOfAction,
    organizationTypes: organizationTypes,
    geographicalScopes: geographicalScopes,
    page: page
  };
}

export function receiveBeneficiarySearch(json) {
  const data = json['organizations'] || {};
  return {
    type: RECEIVE_BENEFICIARY_SEARCH,
    results: {
      items: data['items'],
      total: data['total'],
      maxPages: data['max_pages'],
      page: data['page'],
      suggestion: data['suggestion']
    },
    json: data,
    receivedAt: Date.now()
  };
}

export function fetchBeneficiarySearch(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes, updateQueryString, parentId, searchType) {
  return function (dispatch) {
    // show that we requested user info (we can safely ignore parentId here)
    dispatch(requestBeneficiarySearch(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes, updateQueryString))

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')

    let params = {
      keywords: keywords || undefined,
      page: page  || undefined,
      fields_of_action: fieldsOfAction || undefined,
      organization_types: organizationTypes || undefined,
      geographical_scopes: geographicalScopes || undefined,
      parent: parentId || undefined
    }
    params = queryString.stringify(params)

    let searchUrl = searchType ? searchType : 'beneficiaries';

    return fetch(
      '/api/v2/'+searchUrl+'-search/' + (params ? ('?' + params) : ''),
      {
        credentials: 'same-origin',
        headers: headers
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveBeneficiarySearch(json))
      })
      // TODO: do something on error
  }
}