import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { beneficiarySearchPropType } from '../../utils'
import { fetchBeneficiarySearch } from '../actions'


class BeneficiaryFilterItem extends Component {
  constructor(props) {
    super(props)
    this.toggleHandler = this.toggleHandler.bind(this)
  }

  toggleHandler (e) {
    this.props.clickHandler(e.target.value)
  }

  render () {
    const { value, checked, name, fieldIdx, fieldName } = this.props
    let fieldId=fieldName+''+value+''+fieldIdx;
    return (
      <li className="form-check">
        <input type="checkbox" className="form-check-input" value={value} checked={checked} onClick={this.toggleHandler} id={fieldId} />
        <label className="form-check-label" htmlFor={fieldId}>{name}</label>
      </li>
    )
  }
}

BeneficiaryFilterItem.propTypes = {
  value: PropTypes.number,
  checked: PropTypes.bool,
  name: PropTypes.string,
  clickHandler: PropTypes.func,
  key: PropTypes.string,
  fieldIdx:PropTypes.string,
  fieldName:PropTypes.string
}


class BeneficiarySearchFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unfolded: false,
      choices: props.choices
    }
    this.toggleFilterItem = this.toggleFilterItem.bind(this)
    this.toggleUnfold = this.toggleUnfold.bind(this)
  }

  toggleFilterItem (toggledOptionId) {
    const toggledId = parseInt(toggledOptionId);  // may come in as a String
    // Rewrite values array (with the presence of `toggledOptonId` toggled)
    const newValues = this.state.choices.filter((option, idx) => {
      const wasInValues = this.props.values.indexOf(option.id) > -1
      if (option.id === toggledId) {
        return !wasInValues
      } else {
        return wasInValues
      }
    }).map((option, idx) => {
      return option.id
    })
    // then call toggle handler
    this.props.submitHandler(newValues, toggledId)
  }

  toggleUnfold () {
    this.setState({unfolded: !this.state.unfolded})
  }

  render () {
    const { values, initialVisibleItems, fieldName } = this.props
    let unfoldButton = null
    const that = this
    if(this.state.choices){
      const filterItems = this.state.choices.filter((item, idx) => {
          return (that.state.unfolded || initialVisibleItems === undefined || idx < initialVisibleItems)
      }).map((item, idx) => (
        <BeneficiaryFilterItem checked={values.indexOf(item.id) > -1}
                               value={item.id}
                               name={item.name}
                               clickHandler={that.toggleFilterItem}
                               key={idx}
                               fieldIdx={idx}
                               fieldName={fieldName}/>
      ))

      if (initialVisibleItems > 0) {
        unfoldButton = (
          <li>
            <span className="link more" onClick={this.toggleUnfold}>
              + pokaż {this.state.unfolded ? "mniej" : "więcej"}
            </span>
          </li>
        )
      }

      return (
        <section className="side-filters-wrapper">
          <h3 className="header-small">{this.props.header}</h3>
          <ul className="filters-items">
            {filterItems}
            {unfoldButton}
          </ul>
        </section>
      )
    } else {
      return (
        <section className="side-filters-wrapper" data-missing={this.props.header}></section>
      )
    }
  }
}

BeneficiarySearchFilter.propTypes = {
  choices: PropTypes.array,
  values: PropTypes.array,
  header: PropTypes.string,
  submitHandler: PropTypes.func,
  initialVisibleItems: PropTypes.number
}


class BeneficiarySearchFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formValid: false
    }

    this.setFieldsOfAction = this.setFieldsOfAction.bind(this)
    this.setOrganizationTypes = this.setOrganizationTypes.bind(this)
    this.setGeographicalScopes = this.setGeographicalScopes.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  setFieldsOfAction (newFieldsOfAction, optionId) {
    this.handleSubmit(newFieldsOfAction, null, null)
    ga('send', 'event', 'Wyszukiwanie beneficjentów', 'Filtrowanie wg field_of_action', optionId);
  }

  setOrganizationTypes (newOrganizationTypes, optionId) {
    this.handleSubmit(null, newOrganizationTypes, null)
    ga('send', 'event', 'Wyszukiwanie beneficjentów', 'Filtrowanie wg organization_type', optionId);
  }

   setGeographicalScopes (newGeographicalScopes, optionId) {
    this.handleSubmit(null, null, newGeographicalScopes)
    ga('send', 'event', 'Wyszukiwanie beneficjentów', 'Filtrowanie wg geographical_scope', optionId);
  }

  handleSubmit (fieldsOfAction, organizationTypes, geographicalScopes) {
    const beneficiarySearch = this.props.beneficiarySearch
    this.props.dispatch(
        fetchBeneficiarySearch(
            beneficiarySearch.keywords,
            null,  //page
            fieldsOfAction || beneficiarySearch.fields_of_action,
            organizationTypes || beneficiarySearch.organization_types,
            geographicalScopes || beneficiarySearch.geographical_scopes,
            this.props.updateQueryString
        )
    )
  }

  render () {
    const { beneficiarySearch } = this.props
    const fieldsOfAction = beneficiarySearch.fields_of_action || []
    const organizationTypes = beneficiarySearch.organization_types || []
    const geographicalScopes = beneficiarySearch.geographical_scopes || []

    return (
      <div className="search-filters org-search-filters">
        <BeneficiarySearchFilter choices={FM.fieldsOfActionChoices}
                                 values={fieldsOfAction}
                                 submitHandler={this.setFieldsOfAction}
                                 header="Działania na rzecz..."
                                 fieldName="fieldsofaction"
                                 initialVisibleItems={13}/>

        <BeneficiarySearchFilter choices={FM.organizationTypesChoices}
                                 values={organizationTypes}
                                 submitHandler={this.setOrganizationTypes}
                                 header="Rodzaj organizacji"
                                 fieldName="organizationtype"
                                 initialVisibleItems={10}/>

        <BeneficiarySearchFilter choices={FM.geographicalScopesChoices}
                                 values={geographicalScopes}
                                 submitHandler={this.setGeographicalScopes}
                                 fieldName="geographicalscope"
                                 header="Zasięg działania"/>
      </div>
    )
  }
}

BeneficiarySearchFilters.propTypes = {
  beneficiarySearch: beneficiarySearchPropType,
  updateQueryString: PropTypes.bool
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    beneficiarySearch: state.beneficiarySearch
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(BeneficiarySearchFilters)
