import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//import Slider from 'react-slick'
import { Modal } from 'react-bootstrap'
import BeneficiarySelectModal from '../components/BeneficiarySelectModal'


class BeneficiaryInlineInput extends Component {
  constructor (props) {
    super (props)
    this.state = {
      beneficiary: props.beneficiary,
      modalInitialized: false,
      modalVisible: false
    }

    this.showModal = this.showModal.bind(this)
    this.onModalClosedHandler = this.onModalClosedHandler.bind(this)
  }

  render () {
    const { dispatch, beneficiary, name, id } = this.props

    return (
      <div className="beneficiary-inline-input">
        <input type="hidden" name={name} id={id}
               value={this.state.beneficiary ? this.state.beneficiary.id : ""} />
        { this.state.beneficiary ? (
          <span className="beneficiary-preview">
              <img src={ this.state.beneficiary.main_image } width="32" />
              <span>{ this.state.beneficiary.name }</span>
          </span>
        ) : null }
        <button type="button" className="btn btn-default" onClick={this.showModal}>
          <span className="fa fa-search" /> Wybierz organizację
        </button>
        <BeneficiarySelectModal show={this.state.modalVisible}
                                closedHandler={this.onModalClosedHandler} 
                                hideDismiss={false} />
      </div>
    )
  }

  showModal () {
    // reset search state...?
    this.setState({modalVisible: true})
  }

  onModalClosedHandler (beneficiary) {
    this.setState({modalVisible: false})
    if (beneficiary) {
      this.setState({beneficiary: beneficiary})
    }
  }
}

BeneficiaryInlineInput.propTypes = {
  beneficiary: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    main_image: PropTypes.string,
  })
}

export { BeneficiaryInlineInput as default }
