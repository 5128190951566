import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'


class Fmext1zlCtaButton extends Component {
  render () {
    const { dispatch, userInfo, fmextInfo, fmext1zlInfo, previewedBeneficiaryId } = this.props
    const isFetching = userInfo.isFetching && fmext1zlInfo.isFetching

    if (isFetching) {
      return null
    }

    if (fmext1zlInfo.isGranted
        && fmext1zlInfo.beneficiary
        && fmext1zlInfo.beneficiary.id != parseInt(previewedBeneficiaryId)
        && fmext1zlInfo.beneficiary.parent != parseInt(previewedBeneficiaryId)) {
      //console.log("Not showing `completed` BUTTON - wrong ID")
      return null
    }

    const widgetClasses = classNames("btn btn-red-2 btn-big-cta btn-do-support btn-1zl",
                                   {done: fmext1zlInfo.isGranted})

    return (
      <a className={widgetClasses} rel="nofollow"
         href={"/podaruj1zl/?beneficiary_id="+previewedBeneficiaryId} 
         id="org-1zl-btn">
          <span className="wrap">
              <span className="cta1">Podaruj <span>nam</span> złotówkę</span><br/>
              <span className="cta2">Bezpłatnie. Wystarczą 2 kroki.</span>
              <span className="done"><i className="fa fa-check" aria-hidden="true" /></span>
          </span>
      </a>
    )
  }
}


// FIXME: probabyl should have used propShapes from utils.js
Fmext1zlCtaButton.propTypes = {
  fmextInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isInstalled: PropTypes.bool.isRequired
  }),
  fmext1zlInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isGranted: PropTypes.bool.isRequired
  }),
  userInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number
    }),
    beneficiary: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired
    })
  })
}


// Which props do we want to inject, given the global state?
function getStateSubset(state) {
  return {
    fmextInfo: state.fmextInfo,
    fmext1zlInfo: state.fmext1zlInfo,
    userInfo: state.userInfo
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(getStateSubset)(Fmext1zlCtaButton)