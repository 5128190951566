import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

//TODO: use react-glidejs instead of the globally available
//not working now, because react-glidejs initiates automatically when the modal is still not visible
//import Glide, { Slide } from 'react-glidejs';
import { userInfoPropType, beneficiarySearchPropType } from '../../utils'
import { fetchBeneficiarySearch } from '../actions'
import SimplePagination from '../../components/SimplePagination'
import BeneficiarySearchCriteriaLabels from '../components/CriteriaLabels'
import BeneficiarySearchItem from '../containers/BeneficiarySearchItem'
//import { defaultSliderSettingsNarrow } from '../utils'


function scrollTo (selector, duration) {
  const targetSelector = selector || '#search-scroll-back-anchor';
  const scrollDuration = duration || 500;
  jQuery('html, body').animate({ scrollTop: jQuery(targetSelector).offset().top }, scrollDuration);
}


class BeneficiarySearchResults extends Component {
  constructor(props) {
    super(props)
    this.loadSearchResults = this.loadSearchResults.bind(this)
    this.loadResultsPage = this.loadResultsPage.bind(this)
  }

  componentDidMount() {
    if(this.props.popularOrgs){

      // TODO: move breakpoints to a common setting
      new Glide('#glide-beneficiary-suggestions-popular',{
        keyboard: false,
        perView: 5,
        breakpoints: {
          1024: {
            perView: 4
          },
          992: {
            perView: 3
          },
          640: {
            perView: 2
          },
        }

      }).mount();
    }

    if(this.props.latestOrgs){
      // TODO: move breakpoints to a common setting
      new Glide('#glide-beneficiary-suggestions-latest',{
        keyboard: false,
        perView: 5,
        breakpoints: {
          1024: {
            perView: 4
          },
          992: {
            perView: 3
          },
          640: {
            perView: 2
          },
        }

      }).mount();
    }
  }

  render () {
    // `dispatch` is injected by connect() call:
    const { dispatch, beneficiarySearch, userInfo, itemType, latestOrgs, popularOrgs, parentId, searchType } = this.props
    const { keywords, results, qstring, isFetching, pristine } = beneficiarySearch
    const fieldsOfAction=beneficiarySearch.fields_of_action,
          organizationTypes=beneficiarySearch.organization_types,
          geographicalScopes=beneficiarySearch.geographical_scopes

    let resultsContent = null,
        listTitle = 'Wyniki wyszukiwania',
        noCriteria = !keywords && !fieldsOfAction && !organizationTypes && !geographicalScopes

    if (pristine || noCriteria) {
      return (
        <div>
          {popularOrgs ? (
            <div>
              <h3 className="header-small">Popularne</h3>
              
              <div id="beneficiary-suggestions-popular" className="beneficiary-suggestions">
                <div className="glide glide-beneficiary-suggestions" id="glide-beneficiary-suggestions-popular">
                  <div className="glide__track glide__track--modal" data-glide-el="track">
                    <ul className="glide__slides">
                      {popularOrgs.map((beneficiary, idx) => (
                      <li className="glide__slide" key={idx}>
                        <div>
                          <BeneficiarySearchItem beneficiary={beneficiary}
                                               qstring={qstring}
                                               itemType={itemType}
                                               className="result result-inline organization" />
                        </div>
                      </li>
                    ))}
                    </ul>
                  </div>
                  <div className="glide__arrows glide__arrows--modal" data-glide-el="controls">
                    <span className="glide__arrow glide__arrow--left" data-glide-dir="<"><i className="fa fa-chevron-left"></i></span>
                    <span className="glide__arrow glide__arrow--right" data-glide-dir=">"><i className="fa fa-chevron-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {latestOrgs ? (
            <div>
              <h3 className="header-small">Ostatnio dołączyli</h3>
              <div id="beneficiary-suggestions-latest" className="beneficiary-suggestions">
                <div className="glide glide-beneficiary-suggestions" id="glide-beneficiary-suggestions-latest">
                  <div className="glide__track glide__track--modal" data-glide-el="track">
                    <ul className="glide__slides">
                      {latestOrgs.map((beneficiary, idx) => (
                      <li className="glide__slide" key={idx}>
                        <div>
                          <BeneficiarySearchItem beneficiary={beneficiary}
                                               qstring={qstring}
                                               itemType={itemType}
                                               className="result result-inline organization" />
                        </div>
                      </li>
                    ))}
                    </ul>
                  </div>
                  <div className="glide__arrows glide__arrows--modal" data-glide-el="controls">
                    <span className="glide__arrow glide__arrow--left" data-glide-dir="<"><i className="fa fa-chevron-left"></i></span>
                    <span className="glide__arrow glide__arrow--right" data-glide-dir=">"><i className="fa fa-chevron-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            
          ) : null}
        </div>
      )
    } else if (isFetching) {
      resultsContent = (
        <div className="text-center org-search-in-progress-indicator">
          <span className="fa fa-2x fa-refresh fa-spin" />
        </div>
      )
    } else if (!results.total && !pristine) {
      resultsContent = (
        <div className="col-xs-12 text-center big-top-margin">
          <label>Szukaliśmy, szukaliśmy ale nic nie znaleźliśmy.</label>
          <br />
          <label>Spróbuj zmienić kryteria wyszukiwania.</label>
          <br />
          <br />
          { results.suggestion ? (
            <label>
              Czy chodziło Ci o
              <a href="#" onClick={function(){this.loadSearchResults(results.suggestion)}}>
                {results.suggestion}
              </a>?
            </label>
          ) : null }
      </div>
      )
    } else {
      resultsContent = results.items.map((beneficiary, idx) => (
        
          <BeneficiarySearchItem beneficiary={beneficiary}
                                 qstring={qstring} 
                                 itemType={itemType}
                                 key={idx} />
        
      ))
    }

    return (
      <div className="org-search-results show" id="search-scroll-back-anchor">
        <h3 className="header-small">{listTitle}</h3>
        <BeneficiarySearchCriteriaLabels beneficiarySearch={beneficiarySearch} loadSearchResults={this.loadSearchResults} />
        <div className="row results organizations">
          {resultsContent}
          {!isFetching && results.total ? (
            <div className="col-xs-12 text-center">
              <SimplePagination pagesCount={results.maxPages || 0}
                                currentPage={results.page || 1}
                                loadPageCallback={this.loadResultsPage} />
            </div>
          ) : (null)}
        </div>
      </div>
    )
  }


  loadSearchResults(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes) {
    this.props.dispatch(fetchBeneficiarySearch(keywords, page, fieldsOfAction, organizationTypes, geographicalScopes, this.props.updateQueryString, this.props.parentId, this.props.searchType))
  }

  loadResultsPage(pageNum) {
    const { beneficiarySearch, updateQueryString } = this.props
    this.loadSearchResults(beneficiarySearch.keywords, pageNum, beneficiarySearch.fields_of_action, beneficiarySearch.organization_types, beneficiarySearch.geographical_scopes, updateQueryString)
    scrollTo();
  }
}

BeneficiarySearchResults.propTypes = {
  userInfo: userInfoPropType,
  beneficiarySearch: beneficiarySearchPropType,
  updateQueryString: PropTypes.bool,
  latestOrgs: PropTypes.array,
  popularOrgs: PropTypes.array,
  itemType: PropTypes.string,
  parentId: PropTypes.number,
  searchType: PropTypes.string
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    beneficiarySearch: state.beneficiarySearch
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(BeneficiarySearchResults)