export function convertChoicesToCheckValues (choicesArray, actualValuesArray) {
    let checkValuesObj = []
    choicesArray.map((choice, idx) => {
        let newChoice = Object.assign({}, choice)
        newChoice.checked = (actualValuesArray || []).indexOf(choice.id) > -1
        checkValuesObj.push(newChoice)
    })
    return checkValuesObj
}

export function convertCheckedValuesToParam (checkedValuesArray) {
  let paramValues = [];
  checkedValuesArray.map(prop => {
    if (prop.checked) {
      paramValues.push(prop.id)
    }
  });
  if (paramValues.length === 0) {
    paramValues = null
  }
  return paramValues;
}

export function getToggledOptions (choicesArray, optionIdToToggle) {
  const choicesArrayCopy = choicesArray.slice()
  choicesArrayCopy.map((option, idx) => {
    if (option.id === optionIdToToggle) {
      option.checked = !option.checked
    }
  })
  return choicesArrayCopy
}

export function getToggledOptionsExclusive (choicesArray, optionIdToToggle) {
  // just one option at a time can be checked (as to work like radio input, but you can uncheck)
  const choicesArrayCopy = choicesArray.slice()
  choicesArrayCopy.map((option, idx) => {
    if (option.id === optionIdToToggle) {
      option.checked = !option.checked
    } else {
      option.checked = false
    }
  })
  return choicesArrayCopy
}