import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { przypominajkaInlineInstall } from '../utils_przypominajka'
import { fetch1zlInfo } from '../actions'
import BeneficiarySearchBar from '../beneficiary-search/containers/BeneficiarySearchBar'
import BeneficiarySearchFilters from '../beneficiary-search/containers/BeneficiarySearchFilters'
import BeneficiarySearchResults from '../beneficiary-search/containers/BeneficiarySearchResults'


class FmextGiveAway1zl extends Component {
  constructor(props) {
    super(props)
    this.state = {requestedGrant1zl: false, timeoutsAreSet: false}
  }

  componentWillReceiveProps (nextProps) {
    const { dispatch, userInfo, fmextInfo, fmext1zlInfo } = nextProps
    const fmextInstalled = fmextInfo.isInstalled,
          loggedIn = userInfo.user && userInfo.user.id,
          hasBeneficiary = userInfo.beneficiary && userInfo.beneficiary.id,
          isFetching = (userInfo.isFetching && userInfo.user === null) || fmext1zlInfo.isFetching  // FIXME what about anonymouses?

    if (!this.state.timeoutsAreSet) {
      // when displayed for the first time - set some delayed ajax requests to make sure we get proper 1zl info
      setTimeout(() => dispatch(fetch1zlInfo(false, true)), 3000)
      this.setState({timeoutsAreSet: true})
    }

    if (!isFetching && !this.state.requestedGrant1zl && fmextInstalled && loggedIn && hasBeneficiary && !fmext1zlInfo.isGranted) {
      //console.log("FmextGiveAway1zl: trying to grant 1zl...")
      this.setState({requestedGrant1zl: true})
      dispatch(fetch1zlInfo(true))
      setTimeout(() => dispatch(fetch1zlInfo(true, true)), 3000)
    }
  }

  render () {
    // `dispatch` is injected by connect() call:
    const { dispatch, userInfo, fmextInfo, fmext1zlInfo, beneficiarySearch } = this.props

    const fmextInstalled = fmextInfo.isInstalled,
          loggedIn = userInfo.user && userInfo.user.id,
          hasBeneficiary = userInfo.beneficiary && userInfo.beneficiary.id,
          installLink = (hasBeneficiary ? '/instalacja-rozszerzenia/?beneficiary_id='+userInfo.beneficiary.id : '/instalacja-rozszerzenia/'),
          isFetching = (userInfo.isFetching && userInfo.user === null) || fmext1zlInfo.isFetching  // FIXME what about anonymouses?
          // can't wait for fmextInfo.isFetching as it never settles without an extension being installed

    if (isFetching) {
      // display a spinner
      return (
          <div className="text-center">
            {this.state.requestedGrant1zl ? (<h2 className="title-new">Twoja złotówka jest w drodze...</h2>) : null}
            <i id="install-icon" className="color-fm-green fa fa-spin fa-3x fa-refresh" />
          </div>
      )
    }

    if (fmext1zlInfo.isGranted) {
      const sharedURL = FM.SITE_URL + "/podaruj1zl/?beneficiary_id=" + fmext1zlInfo.beneficiary.id
      return (
          <div className="row thank-you">
            <div className="col-sm-9 col-sm-offset-2 col-md-7 col-md-offset-3">
              <h2 className="title-new">Dziękujemy! Podarowałeś 1zł!</h2>
              <div className="row beneficiary-info">
                <div className="col-sm-2 text-center">
                  <img src={fmext1zlInfo.beneficiary.thumbnail} alt={fmext1zlInfo.beneficiary.name} />
                </div>
                <div className="col-sm-8">
                  <h4 className="name">{fmext1zlInfo.beneficiary.name}</h4>
                  <div>
                    <span className="fb-incentive">
                      Zaproś znajomych aby podarowali nam złotówkę:&nbsp;
                    </span>
                    <div className="fb-share-button"
                         data-href={sharedURL}
                         data-layout="button_count"
                         data-mobile-iframe="true">
                      <a className="fb-xfbml-parse-ignore" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(sharedURL) + "&amp;src=sdkpreparse"}>
                        <img src={FM.STATIC_URL+'img/podaruj1zl/fb_share.png'} alt="Udostępnij na Facebooku"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p>&nbsp;</p>
              <p className="small-text">
                Darowiznę 1&nbsp;zł znajdziesz w swojej <a href="/moje-wsparcie/">historii darowizn</a>.<br/>
                {!fmext1zlInfo.isEmailConfirmed ? (
                  <span>Gdy tylko potwierdzisz swoje konto e-mail, zmieni ona status na &quot;Gotowe do wypłaty&quot;</span>
                ) : null}
              </p>
            </div>
          </div>
      )
    }

    return (
        <div className="podaruj-1zl">
          {userInfo.beneficiary ? (
              <h2 className="title-new">
                {"Podaruj bezpłatnie 1zł dla " + userInfo.beneficiary.name}
                <small>To tylko 2 kroki.</small>
              </h2>
          ) : (
              <h2 className="title-new">
                Już teraz wesprzyj bezpłatnie wybraną organizację
                {loggedIn && fmextInstalled ? null : (
                  <small>
                    Zrób 2 kroki, a wskazana organizacja otrzyma 1zł. Ciebie to nic nie kosztuje.
                  </small>
                )}
              </h2>
          ) }
          <div className="row">
            <div className="col-sm-6 cta-left">
              <i className="fa fa-3x fa-plus" />
              {loggedIn ? (
                  <span className="btn-wrap">
                    <button className="btn btn-grey btn-large disabled" disabled>Zaloguj się</button>
                    <i className="fa fa-5x fa-check color-fm-green" />
                  </span>
              ) : (
                  <span className="btn-wrap">
                    <a href="/konto/zaloguj/?next=/podaruj1zl/" className="btn btn-red btn-large">Zaloguj się</a>
                    <a href="/konto/zaloguj/?next=/podaruj1zl/" className="register-link">Nie masz konta? Zarejestruj się <span className="fa fa-chevron-right"/> </a>
                  </span>
              )}
              {loggedIn && fmextInstalled ? null : (
                <p>
                  Zakładając konto co tydzień możesz otrzymywać informacje
                  o aktualnych kodach rabatowych oraz promocjach
                  w sklepach internetowych oraz możesz śledzić historię darowizn
                  wygenerowanych przy okazji zakupów.
                  <br />
                  <a href={FM.STATIC_URL+'img/podaruj1zl/screen1_1_pow.png'}
                     data-toggle="lightbox"
                     data-gallery="org_images"
                     className="photo-thumb">

                     <img src={FM.STATIC_URL+'img/podaruj1zl/screen1_1.png'} alt="Po zalogownaiu" class="img-responsive"/>
                     <span className="overlay"><span className="fa fa-2x fa-search-plus" /></span>
                  </a>
                  <a href={FM.STATIC_URL+'img/podaruj1zl/screen2_1_pow.png'}
                     data-toggle="lightbox"
                     data-gallery="org_images"
                     className="photo-thumb">

                     <img src={FM.STATIC_URL+'img/podaruj1zl/screen2_1.png'} alt="Po zalogownaiu" class="img-responsive"/>
                     <span className="overlay"><span className="fa fa-2x fa-search-plus" /></span>
                  </a>
                </p>
              )}
            </div>
            <div className="col-sm-6 cta-right">
              {fmextInstalled ? (
                  <span className="btn-wrap">
                    <button className="btn btn-grey btn-large disabled" disabled>Dodaj rozszerzenie</button>
                    <i className="fa fa-5x fa-check color-fm-green" />
                  </span>
              ) : (
                  <span className="btn-wrap">
                    <a href={installLink} className="btn btn-red btn-large instalacja-wtyczki fmext-link" rel="nofollow">Zainstaluj przypominajkę</a>
                    <a href="." className="register-link">Zainstalowana? Sprawdź ponownie <span className="fa fa-refresh"/> </a>
                  </span>
              )}
              {loggedIn && fmextInstalled ? null : (
                <p>
                  Przypominajka do przeglądarek Google Chrome, Mozilla Firefox, Opera i Microsoft Edge Chromium
                 <img src={FM.STATIC_URL+'img/ico-browsers-2.png'} alt="" style={{height: '16px', display: 'inline-block', marginRight: '5px', marginLeft: '5px'}} /> 
                  pokaże komunikat o możliwości przekazania
                  darowizny gdy będziesz na stronie sklepu,
                  partnera FaniMani.pl oraz w trakcie wyszukiwania w Google.
                  <br />
                  <a href={FM.STATIC_URL+'img/podaruj1zl/wizual_wtyczka4_pow.png'}
                     data-toggle="lightbox"
                     data-gallery="org_images"
                     className="photo-thumb">

                      <img src={FM.STATIC_URL+'img/podaruj1zl/wizual_wtyczka4.png'} alt="Tak wygląda wtyczka" class="img-responsive"/>
                      <span className="overlay"><span className="fa fa-2x fa-search-plus" /></span>
                  </a>
                  <a href={FM.STATIC_URL+'img/podaruj1zl/wizual_wtyczka3_pow.png'}
                     data-toggle="lightbox"
                     data-gallery="org_images"
                     className="photo-thumb">

                      <img src={FM.STATIC_URL+'img/podaruj1zl/wizual_wtyczka3.png'} alt="Tak wygląda wtyczka" class="img-responsive"/>
                      <span className="overlay"><span className="fa fa-2x fa-search-plus" /></span>
                  </a>
                </p>
              )}
            </div>
            {loggedIn && fmextInstalled ? (
              <div className="inline-search">
                <h2 className="title-new">
                    <small>
                      Wybierz organizację, której chcesz pomagać.
                    </small>
                </h2>
                <div className="row">
                    <div className="col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3">
                        <div id="beneficiary-search-bar">
                          <BeneficiarySearchBar beneficiarySearch={beneficiarySearch}
                                                updateQueryString={false}/>
                        </div>
                    </div>
                </div>
                <div className="container org-search react-org-search">
                    <div className="row">
                        <div className="col-md-3 search-filters org-search-filters filters-visible">
                            <div id="beneficiary-search-filters">
                              <BeneficiarySearchFilters beneficiarySearch={beneficiarySearch}
                                                        updateQueryString={false}/>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div id="beneficiary-search-results" className="beneficiary-search-results">
                              <BeneficiarySearchResults beneficiarySearch={beneficiarySearch}
                                                        updateQueryString={false}
                                                        resultClassName="BeneficiaryItemSelectInline"/>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
    )
  }
}


// FIXME: probabyl should have used propShapes from utils.js
FmextGiveAway1zl.propTypes = {
  fmextInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isInstalled: PropTypes.bool.isRequired
  }),
  fmext1zlInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isGranted: PropTypes.bool.isRequired,
    isEmailConfirmed: PropTypes.bool.isRequired
  }),
  userInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number
    }),
    beneficiary: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired
    })
  })
}


// Which props do we want to inject, given the global state?
function getStateSubset(state) {
  return {
    fmextInfo: state.fmextInfo,
    fmext1zlInfo: state.fmext1zlInfo,
    userInfo: state.userInfo,
    beneficiarySearch: state.beneficiarySearch
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(getStateSubset)(FmextGiveAway1zl)