import fetch from 'isomorphic-fetch'
import queryString from 'query-string'

export const REQUEST_PARTNER_SEARCH = 'REQUEST_PARTNER_SEARCH'  // for visual feedback
export const REQUEST_PARTNER_CATEGORY = 'REQUEST_PARTNER_CATEGORY'  // for visual feedback
export const FETCH_PARTNER_CATEGORY = 'FETCH_PARTNER_CATEGORY'
export const FETCH_PARTNER_SEARCH = 'FETCH_PARTNER_SEARCH'
export const RECEIVE_PARTNER_SEARCH = 'RECEIVE_PARTNER_SEARCH'
export const RECEIVE_PARTNER_CATEGORY = 'RECEIVE_PARTNER_CATEGORY'

export function requestPartnerSearch(keywords, page, updateQueryString) {
  if (updateQueryString) {
    let parsedQS = queryString.parse(location.search)
    parsedQS.keywords = keywords
    parsedQS.page = page
    delete parsedQS.category
    if (!page) delete parsedQS.page

    const new_qs = queryString.stringify(parsedQS)
    const new_url = window.location.pathname + (new_qs ? ("?" + new_qs) : "")
    history.pushState(null, "", new_url);
  }
  return {
    type: REQUEST_PARTNER_SEARCH,
    keywords: keywords,
    page: page
  };
}

export function requestPartnerCategory(categoryId, page, updateQueryString) {
  if (updateQueryString) {
    let parsedQS = queryString.parse(location.search)
    parsedQS.category = categoryId
    parsedQS.page = page
    delete parsedQS.keywords
    if (!page) delete parsedQS.page

    const new_qs = queryString.stringify(parsedQS)
    const new_url = window.location.pathname + (new_qs ? ("?" + new_qs) : "")
    history.pushState(null, "", new_url);
  }
  return {
    type: REQUEST_PARTNER_CATEGORY,
    categoryId: categoryId,
    page: page
  };
}

export function receivePartnerCategory(json) {
  return {
    type: RECEIVE_PARTNER_CATEGORY,
    results: {
      items: json['results'],
      total: json['total'],
      maxPages: json['max_pages'],
      page: json['page']
    },
    json: json,
    receivedAt: Date.now()
  };
}

export function receivePartnerSearch(json) {
  return {
    type: RECEIVE_PARTNER_SEARCH,
    results: {
      items: json['items'],
      total: json['total'],
      maxPages: json['max_pages'],
      page: json['page'],
      suggestion: json['suggestion']
    },
    json: json,
    receivedAt: Date.now()
  };
}

export function fetchPartnerCategory(categoryId, page, updateQueryString) {
  return function (dispatch) {
    // show that we requested user info
    dispatch(requestPartnerCategory(categoryId, page, updateQueryString))

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')

    let params = {category: categoryId, page: page}
    if (!categoryId) delete params.category
    if (!page) delete params.page

    const parsedQS = queryString.parse(location.search)
    if (parsedQS.hasOwnProperty('iframe') && parsedQS['beneficiary_id']) {
      params.beneficiary_id = parsedQS.beneficiary_id
    }
    
    params = queryString.stringify(params)

    return fetch(
      '/api/v2/partners/' + (params ? ('?' + params) : ''),
      {
        credentials: 'same-origin',
        headers: headers
      })
      .then(response => response.json())
      .then(json =>
        dispatch(receivePartnerCategory(json))
      )
      // TODO: do something on error
  }
}

export function fetchPartnerSearch(keywords, page, updateQueryString) {
  return function (dispatch) {
    // show that we requested user info
    dispatch(requestPartnerSearch(keywords, page, updateQueryString))

    // then fetch the info and update the state
    let headers = new Headers()
    headers.append('Accept', 'application/json')

    let params = {keywords: keywords, page: page}
    if (!page) delete params.page
    params = queryString.stringify(params)

    return fetch(
      '/api/v2/partners-search/' + (params ? ('?' + params) : ''),
      {
        credentials: 'same-origin',
        headers: headers
      })
      .then(response => response.json())
      .then(json =>
        dispatch(receivePartnerSearch(json))
      )
      // TODO: do something on error
  }
}