import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { userInfoPropType, partnerSearchPropType } from '../../utils'
import { fetchPartnerSearch, fetchPartnerCategory } from '../actions'


class PartnerSearchBar extends Component {
  	constructor(props) {
	    super(props)
	    this.state = {
      		keywordsValue: props.partnerSearch.keywords,
      		formValid: false,
      		formTouched: false,
    	}
    	this.handleChange = this.handleChange.bind(this)
    	this.handleSubmit = this.handleSubmit.bind(this)
  	}

  	componentWillReceiveProps (nextProps) {
    	if (this.props.partnerSearch.keywords !== nextProps.partnerSearch.keywords) {
      		this.setState({keywordsValue: nextProps.partnerSearch.keywords});
    	}
  	}

  	render () {
	    // `dispatch` is injected by connect() call:
	    const { dispatch, userInfo, partnerSearch, updateQueryString } = this.props;
	    const btnClass = classNames('btn btn-red d-block w-100', {
	     	'is-loading': partnerSearch.isFetching
	    });
	    const btnIconClass = classNames('fa', {
	    	'fa-search': !partnerSearch.isFetching,
	    	'fa-refresh fa-spin': partnerSearch.isFetching,
	    });
	    const errClass = classNames("invalid-feedback", {
	    	'd-none': this.state.formValid || !this.state.formTouched
	    });
	    const formClass = classNames('row', 'gx-0', {
	    	'was-validated': !this.state.formValid && this.state.formTouched
	    })

    	return (
    			<div className="container-fluid">
	      		<form className={formClass} onSubmit={this.handleSubmit} method="get" name="search_form" role="search">
	        		<div className="col-9">
		          		<input className="form-control keywords"
			                id="input_keywords"
			                name="keywords"
			                type="text"
			                placeholder="Znajdź sklep"
			                required
			                disabled={partnerSearch.isFetching}
			                minLength={partnerSearch.minLength}
			                onChange={this.handleChange}
			                value={this.state.keywordsValue}/>
			            <div className={errClass}>
			            	Proszę wpisać co najmniej {partnerSearch.minLength} znaki.
			          	</div>
			        </div>
			        <div className="col-3">
		          		<button className={btnClass}
		                  	type="submit"
		                  	disabled={!this.state.formValid || partnerSearch.isFetching}>
		            		<span className={btnIconClass} />
		          		</button>		          	
		        	</div>
	      		</form>
	      	</div>
    	)
  	}

  	handleChange (e) {
    	const val = e.target.value

    	this.setState({
	      	keywordsValue: val,
	      	formTouched: true,
	      	formValid: val.trim().length >= this.props.partnerSearch.minLength
    	});
  	}

  	handleSubmit (e) {
	    e.preventDefault();
	    this.props.dispatch(fetchPartnerSearch(this.state.keywordsValue, null, this.props.updateQueryString))
	    // Report search event
	    ga('send', 'event', 'Wyszukiwanie partnerów', 'Wyszukiwanie wg słów kluczowych', this.state.keywordsValue.toLowerCase());
  	}
}


PartnerSearchBar.propTypes = {
  userInfo: userInfoPropType,
  partnerSearch: partnerSearchPropType,
  updateQueryString: PropTypes.bool
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return state;
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(PartnerSearchBar)
