import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import createLogger from 'redux-logger'
import fmaAppReducers from './reducers'

let middlewareList = [thunkMiddleware]
if (!FM.PRODUCTION) {  // on dev env add loggerMiddleware
  middlewareList.push(createLogger())
}

export default function configureStore(initialState) {
  return createStore(
    fmaAppReducers,
    initialState,
    applyMiddleware(...middlewareList)
  )
}