import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { userInfoPropType, discountSearchPropType } from '../../utils'
import { convertChoicesToCheckValues, convertCheckedValuesToParam, getToggledOptions } from '../../utils_checkboxes'


class DiscountTypeSelector extends Component {
  constructor(props) {
    super(props)
    this.toggleType = this.toggleType.bind(this)
    this.state = {
      types: convertChoicesToCheckValues(FM.DiscountTypeChoices, props.discountSearch.types),
      formValid: false
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      types: convertChoicesToCheckValues(FM.DiscountTypeChoices, newProps.discountSearch.types)
    })
  }

  render () {
    if (this.state.types.length == 0) {
      return null
    }
    return (
        <section className="side-filters-wrapper">
            <h3>Typ okazji</h3>
            <ul className="filters-types">
                {this.state.types.map((discountType, idx) => (
                    <li key={idx} className={classNames("checkmark", "link", {'checked':discountType.checked})}>
                        <i value={discountType.id} onClick={this.toggleType} className="fa fa-remove" />
                        <span value={discountType.id} onClick={this.toggleType}>{discountType.name}</span>
                    </li>
                ))}
            </ul>
        </section>
    )
  }

  toggleType (e) {
    const optionId = e.target.value
    const newTypes = getToggledOptions(this.state.types, optionId)
    this.props.loadTypes(convertCheckedValuesToParam(newTypes))

    ga('send', 'event', 'Wyszukiwanie okazji', 'Wyszukiwanie wg types', optionId);
  }
}


DiscountTypeSelector.propTypes = {
  userInfo: userInfoPropType,
  discountSearch: discountSearchPropType,
  loadTypes: PropTypes.func,
  updateQueryString: PropTypes.bool
}

export default DiscountTypeSelector
