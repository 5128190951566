import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userInfoPropType } from '../utils'
import PartnerGoToBtn from './PartnerGoToBtn'


class PartnerPage extends Component {
  render () {
    const { userInfo, partner, goToUrl, donationsDoubling } = this.props
    
    return (
      <div>
        <div className="row partner-intro">
          <h2 className="title-new">
            Kupując w <em>{partner.name}</em>
            { (userInfo.beneficiary === null || userInfo.beneficiary.id==10) ? (
              " pomagasz wybranej przez siebie organizacji"
            ) : (
              " wspierasz " + userInfo.beneficiary.name
            )}
          </h2>

          <p className="support-info">
            <img src={FM.STATIC_URL+'img/heart-2.png'} className="heart" alt="Serce - darowizna" />
            <strong>{partner.discount_text}</strong>
            &nbsp;dla&nbsp;
            <strong>
              { (userInfo.beneficiary === null || userInfo.beneficiary.id==10) ?
                  " wybranej przez Ciebie organizacji" : userInfo.beneficiary.name_genitive||userInfo.beneficiary.name
              }
            </strong>
          </p>

          <div>
            <img src={partner.thumbnail_url} className="partner-logo" alt={partner.name} />
          </div>

          <PartnerGoToBtn partner={partner} goToUrl={goToUrl} />

          { donationsDoubling.length > 0 ? (
            <p className="donations-doubling">Za zakupy {donationsDoubling} podwójna darowizna!</p>
          ) : (null) }
        </div>
      </div>
    )
  }
}


PartnerPage.propTypes = {
  userInfo: userInfoPropType,
  partner: PropTypes.object,
  goToUrl: PropTypes.string,
  donationsDoubling: PropTypes.string
}


class DiscountButton extends Component {
  constructor () {
    super()
    this.goToSearch = this.goToSearch.bind(this)
  }

  render () {
    const { dispatch, userInfo, discountId, classNames, text } = this.props;
    
    return (
      <button className={classNames || "btn btn-red btn-sm-sq-rounded"}
              data-bs-toggle="modal"
              data-bs-target={"#discount-" + discountId + "-modal"}>
          <span>{text || "Pobierz kod rabatowy"} <span className="fa fa-chevron-right" /></span>
      </button>
    )

    /*
    if (userInfo.beneficiary === null) {
      return (
        <button type="button"
                className={classNames || "btn btn-red btn-sm-sq-rounded"}
                onClick={this.goToSearch}>
            <span>{text || "Pobierz kod rabatowy"} <span className="fa fa-chevron-right" /></span>
        </button>
      )
    } else {
      return (
        <button className={classNames || "btn btn-red btn-sm-sq-rounded"}
                data-bs-toggle="modal"
                data-bs-target={"#discount-" + discountId + "-modal"}>
            <span>{text || "Pobierz kod rabatowy"} <span className="fa fa-chevron-right" /></span>
        </button>
      )
    }
    */
    
  }

  goToSearch () {
    document.getElementById('inline-search').scrollIntoView()
    document.getElementById('input_keywords').focus()
  }
}

DiscountButton.propTypes = {
  userInfo: userInfoPropType,
  discountId: PropTypes.string
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  }
}

// Wrap the component to inject dispatch and state into it
DiscountButton = connect(mapStateToProps)(DiscountButton)
PartnerPage = connect(mapStateToProps)(PartnerPage)

export { PartnerPage as default, DiscountButton }
