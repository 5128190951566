import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


class CategoryLink extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  render () {
    const { category, active, liClass } = this.props
    const isActive = active ? active : ''

    return (
        <li className={liClass}>
            <a onClick={this.handleClick} href={"?category="+category.id} className={`nav-link ${isActive}`}>
                {category.name}
            </a>
        </li>
    )
  }

  handleClick (e) {
    e.preventDefault()
    const { category, onClick } = this.props
    onClick(category.id)
    ga('send', 'event', 'Wyszukiwanie partnerów', 'Wyszukiwanie wg kategorii', category.name);
  }
}

CategoryLink.propTypes = {
  category: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool
}


class PartnerSearchCategories extends Component {
  render () {
    const { categories, categoryId, isFetching, loadCategoryResults } = this.props
    const categoryAll = {id:null, name: 'Wszystkie kategorie'}

    let selectedCategoryName = categoryAll.name
    categories.map((category) => {
      if (category.id===categoryId) {
        selectedCategoryName = category.name
      }
    })
    const dropdownBtnClasses = classNames("btn btn-default dropdown-toggle", {
      'is-loading': isFetching
    })

    return (
    	<div>
	        <nav className="navbar d-none d-md-block">
	            <div id="navbar-partner-categories">
	                <ul className="nav shop-categories-nav flex-column">
	                    <CategoryLink category={categoryAll}
	                                  active={categoryId==null}
	                                  onClick={loadCategoryResults}
	                                  liClass='nav-item'
	                                  key="all" />

	                    {categories.map((category, idx) => (
	                        <CategoryLink category={category}
	                                      active={category.id==categoryId}
	                                      onClick={loadCategoryResults}
	                                      liClass='nav-item'
	                                      key={idx} />)
	                    )}
	                </ul>
	            </div>
	        </nav>
	        <div className="dropdown d-block d-md-none">
	          	<button className="btn btn-light w-100 mt-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
	            	Kategorie sklepów
	          	</button>
	          	<ul className="dropdown-menu">
	          		{categories.map((category, idx) => (
	                    <CategoryLink category={category}
	                                  active={category.id==categoryId}
	                                  onClick={loadCategoryResults}
	                                  liClass='dropdown-item'
	                                  key={idx} />)
	                )}
	          	</ul>
	        </div>
	    </div>
    )
  }
}

PartnerSearchCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  loadCategoryResults: PropTypes.func.isRequired,
  categoryId: PropTypes.number
}

export { CategoryLink, PartnerSearchCategories }
export default PartnerSearchCategories
