import { DEFAULT_STATE } from '../utils'

import {
  REQUEST_BENEFICIARY_SEARCH,
  FETCH_BENEFICIARY_SEARCH,
  RECEIVE_BENEFICIARY_SEARCH
} from './actions'

export function beneficiarySearchHandler(state=DEFAULT_STATE.beneficiarySearch, action={}) {
  // state is beneficiarySearch here
  switch (action.type) {
    case RECEIVE_BENEFICIARY_SEARCH:
      return Object.assign({}, state, {
        isFetching: false,
        results: action.results
      })
    case REQUEST_BENEFICIARY_SEARCH:
      return Object.assign({}, state,
        {
          isFetching: true,
          pristine: false,
          categoryId: null,
          keywords: action.keywords,
          fields_of_action: action.fieldsOfAction,
          organization_types: action.organizationTypes,
          geographical_scopes: action.geographicalScopes,
          page: action.page
        }
      )
    default:
      return state;
  }
}
