import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class SimplePagination extends Component {
	constructor(props) {
    	super(props)
    	this.loadNextPage = this.loadNextPage.bind(this)
    	this.loadPrevPage = this.loadPrevPage.bind(this)
  	}

  	render() {
	    const { pagesCount, currentPage, loadPageCallback } = this.props

	    const prevClassNames = classNames({disabled: currentPage == 1}, 'arrow', 'page-item')
	    const nextClassNames = classNames({disabled: currentPage == pagesCount}, 'arrow', 'page-item')

	    if (pagesCount > 1) {
	      	return (
	        	<nav>
	        		<ul className="pagination justify-content-center mt-2">
	          			<li className={prevClassNames} aria-label="Previous">
	            			<span className="link page-link" onClick={this.loadPrevPage}>
	              				&#60;
	            			</span>
	          			</li>
	          			<li className="page-item disabled dotted">
	              			<span className="page-link page-text">Strona {currentPage} z {pagesCount}</span>
	          			</li>
	          			<li className={nextClassNames} aria-label="Next">
	            			<span className="link page-link" onClick={this.loadNextPage}>
	              				&#62;
	            			</span>
	          			</li>
	        		</ul>
	        	</nav>
	      	)
    	}
    	return null
  	}

  	loadNextPage (e) {
    	const { pagesCount, currentPage, loadPageCallback } = this.props
    	//e.preventDefault();
    	if (currentPage < pagesCount) {
      		loadPageCallback(currentPage + 1)
    	}
  	}

  	loadPrevPage (e) {
    	const { currentPage, loadPageCallback } = this.props
    	//e.preventDefault();
    	if (currentPage > 1) {
      		loadPageCallback(currentPage -1)
    	}
  	}
}

SimplePagination.propTypes = {
	pagesCount: PropTypes.number.isRequired,
  	currentPage: PropTypes.number.isRequired,
  	loadPageCallback: PropTypes.func.isRequired
}

export default SimplePagination