import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userInfoPropType, partnerSearchPropType } from '../../utils'
import PartnerSearchBar from '../containers/PartnerSearchBar'
import PartnerSearchResults from '../components/PartnerSearchResults'
import PartnerSearchCategories from '../components/PartnerSearchCategories'
import { fetchPartnerCategory, fetchPartnerSearch } from '../actions'


function scrollTo (selector, duration) {
  const targetSelector = selector || '#search-scroll-back-anchor';
  const scrollDuration = duration || 500;
  jQuery('html, body').animate({ scrollTop: jQuery(targetSelector).offset().top }, scrollDuration);
}

class PartnerSearchFull extends Component {
  constructor(props) {
    super(props)
    this.loadCategoryResults = this.loadCategoryResults.bind(this)
    this.loadSearchResults = this.loadSearchResults.bind(this)
    this.loadResultsPage = this.loadResultsPage.bind(this)
  }

  render () {
    // `dispatch` is injected by connect() call:
    const { dispatch, userInfo, partnerSearch, updateQueryString } = this.props

    return (
      <div className="row">
        <div className="col-md-3">
          <PartnerSearchBar partnerSearch={partnerSearch}
                            updateQueryString={updateQueryString}/>

          <PartnerSearchCategories
            categories={partnerSearch.categories}
            categoryId={partnerSearch.categoryId}
            isFetching={partnerSearch.isFetching}
            loadCategoryResults={this.loadCategoryResults} />
        </div>

        <div className="col-md-9" id="partners_list_container">
            <PartnerSearchResults {...partnerSearch}
              loadSearchResults={this.loadSearchResults}
              loadResultsPage={this.loadResultsPage} />
        </div>
      </div>
    )
  }

  loadCategoryResults(categoryId, page) {
    this.props.dispatch(fetchPartnerCategory(categoryId, page, this.props.updateQueryString))
  }

  loadSearchResults(keywords, page) {
    this.props.dispatch(fetchPartnerSearch(keywords, page, this.props.updateQueryString))

  }

  loadResultsPage(pageNum) {
    const { partnerSearch, updateQueryString } = this.props
    if (partnerSearch.keywords) {
      this.loadSearchResults(partnerSearch.keywords, pageNum, updateQueryString)
      scrollTo();
    } else {
      this.loadCategoryResults(partnerSearch.categoryId, pageNum, updateQueryString)
      scrollTo();
    }
  }
}

PartnerSearchFull.propTypes = {
  userInfo: userInfoPropType,
  partnerSearch: partnerSearchPropType,
  updateQueryString: PropTypes.bool
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    partnerSearch: state.partnerSearch
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(PartnerSearchFull)