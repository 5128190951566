import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { userInfoPropType, discountSearchPropType } from '../../utils'
import {
    convertChoicesToCheckValues,
    convertCheckedValuesToParam,
    getToggledOptionsExclusive
} from '../../utils_checkboxes'


class DiscountCategorySelector extends Component {
  constructor(props) {
    super(props)
    this.toggleCategory = this.toggleCategory.bind(this)
    this.state = {
      categories: convertChoicesToCheckValues(FM.DiscountCategoryChoices, props.discountSearch.categories)
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      categories: convertChoicesToCheckValues(FM.DiscountCategoryChoices, newProps.discountSearch.categories)
    })
  }

  render () {
    if (this.state.categories.length === 0) {
      return null
    }
    return (
        <section>
            <h3>Kategoria</h3>
            <ul className="filters-category">
                {this.state.categories.map((category, idx) => (
                    <li key={idx} className={classNames("checkmark", "link", {'checked':category.checked})}>
                        <i value={category.id} onClick={this.toggleCategory} className="fa fa-remove" />
                        <span value={category.id} onClick={this.toggleCategory}>{category.name}</span>
                    </li>
                ))}
            </ul>
        </section>
    )
  }

  toggleCategory (e) {
    const categoryId = e.target.value
    const newCategories = getToggledOptionsExclusive(this.state.categories, categoryId)
    this.props.loadCategories(convertCheckedValuesToParam(newCategories))

    ga('send', 'event', 'Wyszukiwanie okazji', 'Wyszukiwanie wg category', categoryId);
  }
}


DiscountCategorySelector.propTypes = {
  userInfo: userInfoPropType,
  discountSearch: discountSearchPropType,
  updateQueryString: PropTypes.bool
}

export default DiscountCategorySelector
