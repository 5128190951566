import queryString from 'query-string'
import { fetchPartnerSearch, fetchPartnerCategory } from './actions'
import { normalizeParsedQS } from '../utils'

export function loadPartnerInitialSearchResults (store) {
  const parsedQS = queryString.parse(location.search)
  if (parsedQS.category === 'null') delete parsedQS.category  // some browsers try to set 'null' here
  normalizeParsedQS(parsedQS)

  if (parsedQS.keywords) {
    store.dispatch(fetchPartnerSearch(parsedQS.keywords, parsedQS.page))
  }
  else if (parsedQS.category && parsedQS.category[0]) {
    store.dispatch(fetchPartnerCategory(parsedQS.category, parsedQS.page))
  }
  else {
    store.dispatch(fetchPartnerCategory(null, parsedQS.page))
  }
}

export function getPartnerQueryStringChangeHandler(store) {
  return function () {
    const partnerSearch = store.getState().partnerSearch
    let parsedQS = queryString.parse(location.search)
    normalizeParsedQS(parsedQS)

    if (parsedQS.keywords) {
      if (partnerSearch.keywords !== parsedQS.keywords || partnerSearch.page !== parsedQS.page) {
        store.dispatch(fetchPartnerSearch(parsedQS.keywords, parsedQS.page))
      }
    }
    else if (parsedQS.category) {
      if (partnerSearch.categoryId !== parsedQS.category || partnerSearch.page !== parsedQS.page) {
        store.dispatch(fetchPartnerCategory(parsedQS.category, parsedQS.page))
      }
    }
    else if (!parsedQS.keywords && !parsedQS.category) {
      store.dispatch(fetchPartnerCategory(null, parsedQS.page))
    }
  }
}