import React, { Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'


class FmextCtaWidget extends Component {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)

    this.state = {visible: false, closed: false, pristine: true}
    setTimeout(() => {this.setState({visible: true});}, 3000)
  }

  render () {
    const { dispatch, userInfo, fmextInfo, fmext1zlInfo } = this.props
    const isFetching = userInfo.isFetching && fmext1zlInfo.isFetching

    if (fmext1zlInfo.isGranted || isFetching) {
      return null
    }

    const widgetClasses = classNames("widget-1zl", {visible: this.state.visible, closed: this.state.closed})

    return (
      <a className={widgetClasses} href="/podaruj1zl/">
        <span className="wrap">
          <span className="cta1">Podaruj złotówkę</span>
          <span className="cta2">Bezpłatnie. Wystarczą 2 kroki. <span className="fa fa-chevron-right"/></span>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}><span aria-hidden="true">×</span></button>
        </span>
      </a>
    )
  }

  handleClose () {
    this.setState({closed: true, visible: false})
  }
}


// FIXME: probabyl should have used propShapes from utils.js
FmextCtaWidget.propTypes = {
  fmextInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isInstalled: PropTypes.bool.isRequired
  }),
  fmext1zlInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isGranted: PropTypes.bool.isRequired
  }),
  userInfo: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number
    }),
    beneficiary: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired
    })
  })
}


// Which props do we want to inject, given the global state?
function getStateSubset(state) {
  return {
    fmextInfo: state.fmextInfo,
    fmext1zlInfo: state.fmext1zlInfo,
    userInfo: state.userInfo
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(getStateSubset)(FmextCtaWidget)