import { combineReducers } from 'redux'
import {
  SET_BENEFICIARY,
  RECEIVE_USER_INFO,
  REQUEST_USER_INFO,
  RECEIVE_FMEXT_INFO,
  REQUEST_FMEXT_INFO,
  REQUEST_1ZL_INFO,
  RECEIVE_1ZL_INFO
} from './actions'
import { DEFAULT_STATE } from './utils'
import { beneficiarySearchHandler } from './beneficiary-search/reducers'
import { partnerSearchHandler } from './partner-search/reducers'
import { discountSearchHandler } from './discounts/reducers'


function userInfoHandler(state=DEFAULT_STATE.userInfo, action={}) {
  // state is userInfo here
  switch (action.type) {
    case RECEIVE_USER_INFO:
      return Object.assign({}, state, action.userInfo, {
        isFetching: false,
        isSettingBeneficiary: false,
        isAnonymous: !Boolean(action.userInfo.user)
      })
    case REQUEST_USER_INFO:
      return Object.assign({}, state, {isFetching: true})
    case SET_BENEFICIARY:
      return Object.assign({}, state, {isFetching: true, isSettingBeneficiary: true})
    default:
      return state;
  }
}

function fmextInfoHandler(state=DEFAULT_STATE.fmextInfo, action={}) {
  // state is userInfo here
  switch (action.type) {
    case RECEIVE_FMEXT_INFO:
      return Object.assign({}, state, action.fmextInfo, {
        isFetching: false
      })
    case REQUEST_FMEXT_INFO:
      return Object.assign({}, state, {isFetching: true})
    default:
      return state;
  }
}

function fmext1zlInfoHandler(state=DEFAULT_STATE.fmext1zlInfo, action={}) {
  // state is userInfo here
  switch (action.type) {
    case RECEIVE_1ZL_INFO:
      return Object.assign({}, state, action.fmext1zlInfo, {isFetching: false})
    case REQUEST_1ZL_INFO:
      return Object.assign({}, state, {isFetching: true})
    default:
      return state;
  }
}


let fmaAppReducers = combineReducers({
  beneficiarySearch: beneficiarySearchHandler,
  partnerSearch: partnerSearchHandler,
  discountSearch: discountSearchHandler,
  userInfo: userInfoHandler,
  fmextInfo: fmextInfoHandler,
  fmext1zlInfo: fmext1zlInfoHandler
});

export default fmaAppReducers;