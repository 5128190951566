import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { setBeneficiary } from '../actions'
import BeneficiarySelectModal from '../beneficiary-search/components/BeneficiarySelectModal'


class PartnerGoToBtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRedirecting: false,
      showModal: false,
    }
    this.clickedGoToShopHandler = this.clickedGoToShopHandler.bind(this)
    this.modalClosedHandler = this.modalClosedHandler.bind(this)
  }

  render () {
    const { dispatch, userInfo, partner, goToUrl } = this.props
    const isInProgress = userInfo.isFetching || this.state.isRedirecting
    const btnClasses = classNames({ // additional classes for btn
      'is-redirecting': this.state.isRedirecting,
      'is-fetching': userInfo.isFetching,
      'is-loading': isInProgress,  //general purpose class (to show/hide spinner icon)
    })
    const btnIconClasses = classNames('fa', {
      'fa-circle-o-notch': isInProgress,
      'fa-spin': isInProgress,
      'fa-chevron-right': !isInProgress,
    })

    return (
      <span className={"btn-go-to-shop-wrapper"}>
        <button onClick={this.clickedGoToShopHandler}
                data-go-url={goToUrl}
                data-partner-id={partner.id}
                className={"btn btn-red btn-go-to-shop " + btnClasses}
                disabled={isInProgress}>
          { this.state.isRedirecting ? (
            <span>Lecimy do sklepu...</span>
          ) : (
            <span>Przejdź do sklepu i&nbsp;pomagaj&nbsp;</span>
          ) }
          <span className={btnIconClasses} />
        </button>

        { userInfo.isAnonymous ? (
          <div>Masz już konto? <a href={'/konto/zaloguj/?next='+window.location.pathname}>Zaloguj się</a></div>
        ) : (
          (null)
        ) }

        
        <BeneficiarySelectModal show={this.state.showModal}
                                closedHandler={this.modalClosedHandler}
                                dispatch={dispatch} 
                                userInfo={userInfo}
                                hideDismiss={true} />
      </span>
    )
  }

  clickedGoToShopHandler (e) {
    const { userInfo } = this.props;
    if (userInfo.isFetching || this.state.isRedirecting) {
      return
    }

    // hide any other opened modal
    //jQuery('.modal').modal('hide');
    //jQuery('#shop-opened-modal').modal(); // and then open info-modal

    this.setState({isRedirecting: true});
    if (userInfo.beneficiary) {
      if(userInfo.isAnonymous && (!userInfo.beneficiary || userInfo.beneficiary.id==10)){
        e.preventDefault('Anonymous with beneficiary_id=10, showing ben. inline modal');
        this.setState({showModal: true});
      } else {
        window.location = this.props.goToUrl;  
      }
    } else {
      e.preventDefault('showing ben. inline modal');
      this.setState({showModal: true});
    }
  }

  modalClosedHandler (selectedBeneficiary) {
    const { dispatch, goToUrl } = this.props

    if (selectedBeneficiary) {
      // Selected a beneficiary so we can continue to the partner page
      dispatch(setBeneficiary(selectedBeneficiary))

      // Add beneficiary ID to the redirect URL and go...
      // but after 0,3 sec as to let setBeneficiary complete
      setTimeout(() => {
        // Also add beneficiary id to URL in case above `setBeneficiary` failed.
        const joinChar = (goToUrl.indexOf('?') > -1) ? '&' : '?';
        window.location = goToUrl + joinChar + 'beneficiary_id=' + selectedBeneficiary.id;
      }, 300);

    } else {
      this.setState({isRedirecting: false}) // just disable spinner
    }

    this.setState({showModal: false})  // hide modal
  }
}

PartnerGoToBtn.propTypes = {
  partner: PropTypes.object,
  goToUrl: PropTypes.string,
  userInfo: PropTypes.object,  // userInfoPropType ?
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  }
}

// Wrap the component to inject dispatch and state into it
PartnerGoToBtn = connect(mapStateToProps)(PartnerGoToBtn)

export { PartnerGoToBtn as default }
